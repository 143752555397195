import React, {useState, useEffect, useContext} from 'react';
import {Container, Grid, Box, Typography, Button, Card, Tabs, Tab} from '@mui/material';
import FeedItem from "./components/FeedItem";
import {UserContext} from "../../context/UserContext";
import {CategoryButton} from "../CreatorProfile";
import axios from "axios";
import {useApiClient} from "../../hooks/useApiClient";
import {Wrapper} from "../Home";
import {PropagateLoader, PulseLoader} from "react-spinners";
import {useParams, useSearchParams} from "react-router-dom";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
             <Box>
                    {children}
             </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const  TABS_MAP = {
    0: 'new',
    1: 'applied'
}
const  TABS_MAP_FROM_PARAMS = {
    'new': 0,
    'applied': 1,
}
const Feed = () => {
    const apiClient = useApiClient();
    let [searchParams, setSearchParams] = useSearchParams();

    const [feedItems, setFeedItems] = useState([]);
    const [loadingFeed, setLoadingFeed] = useState(false);
    const { user, loading } = useContext(UserContext);
    const [value, setValue] = useState(TABS_MAP_FROM_PARAMS[searchParams.get('status')] || 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchParams({status: TABS_MAP[newValue]})
    };

    useEffect(() => {
        if (searchParams.get('status')) {
            setValue(TABS_MAP_FROM_PARAMS[searchParams.get('status')])
        }
    }, [searchParams.get('status')])


    useEffect(() => {
        const getFeed = async (state = "new") => {
            try {
                setLoadingFeed(true)
                const res = await apiClient.get(`/fetch-matching-campaigns?user_id=${user._id}&no_cache=${true}&state=${TABS_MAP[value]}`);
                setLoadingFeed(false)

                if (res?.data.length > 0) {

                    setFeedItems(res.data)
                } else {

                    setFeedItems([]);
                }
            } catch (err) {
                console.error(err);
            }
        };
        getFeed()
    }, [value]);

    const loadMoreItems = () => {
        // Fetch more feed items and update the state
        // e.g., setFeedItems([...feedItems, ...newItems]);
    };

    return (

        <Container sx={{ marginTop: '64px' , padding: '0px 80px'}}>
            <Typography variant="h5" align="center"  sx={{  mb: 1 ,color: (theme) => theme.palette.text.primary }}>
                Recommended for you
            </Typography>
            <Typography variant="subtitle2" align="center" sx={{ mb: 2 }}>
                Based on your profile, channel niche and categories
            </Typography>
            <Tabs centered value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab sx={{width: 300}} disableRipple label="New" {...a11yProps(0)} />
                <Tab sx={{width: 300}} disableRipple label="Applied" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
                <Grid container justifyContent="center" sx={{ my: 4 }}>
                    {user?.categories?.map((category, index) => (
                        <Grid item key={index}>
                            <CategoryButton variant="outlined">{category}</CategoryButton>
                        </Grid>
                    ))}
                </Grid>
                {
                    loadingFeed ?
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <PropagateLoader
                                color={'#818181'}
                                loading={true}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </Box>
                        :
                        <Grid container spacing={4}>
                            {feedItems
                                .sort((a, b) => b.matching_percentage - a.matching_percentage)
                                .map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <FeedItem item={item} />
                                    </Grid>
                                ))}
                        </Grid>
                }
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Grid container justifyContent="center" sx={{ my: 4 }}>
                    {user?.categories?.map((category, index) => (
                        <Grid item key={index}>
                            <CategoryButton variant="outlined">{category}</CategoryButton>
                        </Grid>
                    ))}
                </Grid>
                {
                    loadingFeed ?
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <PropagateLoader
                                color={'#818181'}
                                loading={true}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </Box>
                        :
                        <Grid container spacing={4}>
                            {feedItems
                                .sort((a, b) => b.matching_percentage - a.matching_percentage)
                                .map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <FeedItem item={item} />
                                    </Grid>
                                ))}
                        </Grid>
                }
            </CustomTabPanel>
        </Container>

    );
};

export default Feed;
