import React, {useContext, useEffect, useState} from 'react';
import SendbirdApp from '@sendbird/uikit-react/App';
import '@sendbird/uikit-react/dist/index.css';
import '../styles.css';
import { Box, Button, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import {UserContext} from "../../../context/UserContext";
import SponsorshipsTable from "../../../components/Table";
import {FadeLoader, PropagateLoader, PulseLoader} from "react-spinners";
import {Wrapper} from "../../Home";
import EmptyState from "../../../assets/empty-state.svg"
import styled from "styled-components";
import VideoDetails from "../../../components/VideoDetails";
import {useApiClient} from "../../../hooks/useApiClient";
import {OverviewSponsors} from "../overview/overview-sponsors";
import {OverviewSpent} from "../overview/overview-spent";
import {OverviewGainedViews} from "../overview/overview-gained-views";
import {withUserType} from "../withUserType";
import {OverviewCreators} from "../overview/overview-creators";
import CountUp from "react-countup";
import axios from "axios";

import StatusMenu from "../../../components/StatusMenu";


const CustomTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        height: '42px', // set the height
        padding: '0px 16px', // adjust padding as necessary
        fontSize: '14px', // adjust font size as necessary
        borderRadius: '0px 8px 8px 0px !important'

    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px 0px 0px 8px !important'
    },
});

const Badge = styled.span`
  display: inline-block;
  padding: 0.2em 1em;
  border-radius: 10px;
  color: white;
  width: 106px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  background-color: ${props => {
    if (props.status === 'pending') return '#f0ad4e';
    if (props.status === 'accepted') return '#5cb85c';
    if (props.status === 'rejected') return '#d9534f';
    if (props.status === 'in negotiation') return '#0275d8'; // Bootstrap's info color
    if (props.status === 'postponed') return '#5bc0de'; // Bootstrap's info color
}};
`;

const ActionButton = ({color,variant, children, onClick, loading}) => {
    return (
        <Button
            variant={variant}
            sx={{minWidth: 100}}
            color={color}
            startIcon={<FadeLoader cssOverride={{
                "top": "7px",
                "left": "-4px",
                "height":"0px",
                "width":"0px",
            }} margin={-10} height={5} width={3} speedMultiplier={3} color={'#fff'} loading={loading} />}
            onClick={onClick}
        >
            {children}
        </Button>
    )
}


const BrandDashboard = () => {
    const [loadingSpons, setLoadingSpons] = useState(true);  // <-- state for storing the URL
    const [sponsorships, setSponsorships] = useState(null);  // <-- state for storing the URL
    const [hasSponsorships, setHasSponsorships] = useState(false);
    const [totalSponsoredViews, setTotalSponsoredViews] = useState(0);
    const [totalAmountOwed, setTotalAmountOwed] = useState(0);
    const [totalCreators, setTotalCreators] = useState(0);

    const { user } = useContext(UserContext);
    const apiClient = useApiClient();

    useEffect(()=>{
        fetchSponsorships()
    },[])

    const fetchSponsorships = async (noCache = false) => {

        try {
            const res = await apiClient.get(`/v2/creator-sponsorships?user_id=${user._id}&no_cache=${noCache}`);
            setLoadingSpons(false)
            if (res?.data) {
                if (sponsorships) {
                    const oldRowsMap = sponsorships?.reduce((acc, row) => {
                        acc[row._id] = row;
                        return acc;
                    }, {});

                    res.data.forEach(row => {
                        if (oldRowsMap[row._id]) {
                            row.previous_pay_per_view_rate = oldRowsMap[row._id].pay_per_view_rate;
                            row.previous_current_views_count = oldRowsMap[row._id].current_views_count;
                            row.previous_gained_views = oldRowsMap[row._id].gained_views;
                            row.previous_total_amount_owed = oldRowsMap[row._id].total_amount_owed;
                            row.previous_engagement_rate = oldRowsMap[row._id].engagement_rate * 100;
                        }
                    });
                }

                const totalSponsoredViewsSum = res.data.reduce((total, sponsorship) => {
                    return total + parseFloat(sponsorship.gained_views || 0);
                }, 0);

                const totalCurrentViewsSum = res.data.reduce((total, sponsorship) => {
                    return total + parseFloat(sponsorship.current_views_count || 0);
                }, 0);

                const totalAmountOwedSum = res.data.reduce((total, sponsorship) => {
                    return total + parseFloat(sponsorship.total_amount_owed || 0);
                }, 0);

                let uniqueCreators = new Set();

                for (let item of res.data) {
                    uniqueCreators.add(item.creator_id);
                }
                // Set state
                setTotalCreators(uniqueCreators.size);
                setTotalSponsoredViews(totalSponsoredViewsSum);
                setTotalAmountOwed(totalAmountOwedSum);
                setSponsorships(res.data);
                setHasSponsorships(true) // Set hasSponsorships to true if sponsorships are not empty
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(()=>{
        // Run fetchSponsorships immediately after component mounts
        fetchSponsorships(true);

        // If there are sponsorships, setup the interval
        if (hasSponsorships) {
            const intervalId = setInterval(() => fetchSponsorships(true), 60000); // Fetch every 60 seconds with parameter true
            // Cleanup function to clear the interval when component unmounts
            return () => clearInterval(intervalId);
        }
    }, [hasSponsorships]) // Rerun useEffect when hasSponsorships changes


    const handleUpdateStatus = async (sponsorshipId, status) => {
        try {
            setLoadingSpons(true)
            const response = await apiClient.put(`${process.env.REACT_APP_API_URL}/user/${user._id}/sponsorship/${sponsorshipId}`, {
                status: status
            });
            if (response.status === 200) {
                // At this point, you might want to update your state to reflect the status change
                await fetchSponsorships(true);
            } else {
                console.error(`Failed to update status for sponsorship ${sponsorshipId}`);
            }
        } catch (error) {
            console.error(`Failed to update status for sponsorship ${sponsorshipId}`);
            console.error(error);
        } finally {
        }
    };

    const totalPreviousTotalAmountOwed = sponsorships?.reduce((total, sponsorship) => {
        return total + parseFloat(sponsorship.previous_total_amount_owed || 0);
    }, 0);

    if(loadingSpons){
        return <Wrapper> <PropagateLoader
            color={'#818181'}
            loading={true}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
        </Wrapper>
    }

    const columns = [
        {
            field: "video_title",
            headerName: "Video",
            align: "left",
            component: "th",
            scope: "row",
        },
        {
            field: "creator_name",
            headerName: "Creator",
            align: "center",
        },
        {
            field: "status",
            headerName: "Status",
            align: "center",
            renderCell: (row) => <Badge status={row.status}>{row.status}</Badge>,
        },
        {
            field: "engagement_rate",
            headerName: "Engagement Rate",
            align: "center",
            renderCell: (row) => <CountUp prefix="%" start={row.previous_engagement_rate} end={row.engagement_rate * 100} />,
        },
        {
            field: "pay_per_view_rate",
            headerName: "CPM",
            align: "center",
            renderCell: (row) => <CountUp prefix="$" start={row.previous_pay_per_view_rate} end={row.pay_per_view_rate} />,
        },
        {
            field: "current_views_count",
            headerName: "Current Views",
            align: "center",
            renderCell: (row) => <CountUp start={row.previous_current_views_count} end={row.current_views_count} />,
        },
        {
            field: "gained_views",
            headerName: "Sponsored Views",
            align: "center",
            renderCell: (row) => <CountUp start={row.previous_gained_views} end={row.gained_views} />,
        },
        {
            field: "total_amount_owed",
            headerName: "Total Amount Owed",
            align: "center",
            renderCell: (row) => <CountUp prefix="$" start={row.previous_total_amount_owed} end={row.total_amount_owed} />,
        },
        {
            field: 'cta',
            headerName: 'Action',
            align: 'center',
            renderCell: (row) => (
                (row.status !== "accepted" && row.status !== "rejected" )?
                    <StatusMenu
                        row={row}
                        handleUpdateStatus={handleUpdateStatus}
                    /> : <></>
            ),
        }
    ];


    return (
                <Grid
                    sx={{padding: '40px'}}
                    container
                    spacing={2}
                    rowSpacing={4}
                >
                    <Grid
                        item
                        xs={4}
                        md={4}
                        lg={3}
                    >
                        <OverviewSponsors difference={33} positive={true} value={sponsorships?.length || 0} sx={{height: '100%'}}/>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        md={4}
                        lg={3}
                    >
                        <OverviewSpent difference={14} positive={true} value={totalAmountOwed || 0} sx={{height: '100%'}}/>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        md={4}
                        lg={3}
                    >
                        <OverviewGainedViews value={totalSponsoredViews} sx={{height: '100%'}}/>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        md={4}
                        lg={3}
                    >
                        <OverviewCreators positive={true} value={totalCreators} sx={{height: '100%'}}/>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        md={12}
                    >
                        <Box mb={3}>
                            {loadingSpons
                                ? <PropagateLoader
                                    color={'#818181'} loading={true} size={20} />
                                : sponsorships && sponsorships.length > 0
                                    ?
                                    <SponsorshipsTable type="brand" rows={sponsorships} columns={columns} />
                                    : <Box className="empty-state">
                                        <img className="empty-img" src={EmptyState} />
                                        <Box className="empty-text">
                                            Currently you have no sponsorships
                                        </Box>
                                    </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
    );
};
export default BrandDashboard;