import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import {BrandProfileDetails} from "./components/BrandProfileDetails";
import {BrandProfileMeta} from "./components/BrandProfileMeta";
import {UserContext} from "../../context/UserContext";
import {useContext} from "react";

const BrandProfile = () => {
    const { user} = useContext(UserContext);


    return(<>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <div>
                        <Typography variant="h4">
                            Account
                        </Typography>
                    </div>
                    <div>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                xs={12}
                                md={6}
                                lg={4}
                            >
                                <BrandProfileMeta details={user}/>
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                                lg={8}
                            >
                                <BrandProfileDetails details={user}/>
                            </Grid>
                        </Grid>
                    </div>
                </Stack>
            </Container>
        </Box>
    </>
    )
};

export default BrandProfile;