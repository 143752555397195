import React, {useContext, useEffect, useState} from 'react';
import './styles.css';
import  {useNavigate} from "react-router-dom";
import {styled} from "@mui/material";
import {PropagateLoader, PulseLoader} from "react-spinners";

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {UserContext} from "../../context/UserContext";
import axios from "axios";
import {useGoogleSignIn} from "../../hooks/useGoogleSignIn";

const provider = new GoogleAuthProvider();
// provider.addScope('https://www.googleapis.com/auth/youtube.readonly');
// provider.addScope('https://www.googleapis.com/auth/yt-analytics.readonly');
// provider.addScope('https://www.googleapis.com/auth/youtube.force-ssl');

export const Wrapper = styled('div')({
    width: 'calc(100% + 240px)',
    minHeight: '100vh',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
});

export const FullWidthWrapper = styled('div')({
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
});

const Home = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { signIn } = useGoogleSignIn(); // Use the hook
    const auth = getAuth();

    const handleClick = async (e) => {
        e.stopPropagation();
        await signIn(); // Use the sign-in function
    };

    return (
        <div className="home">
            <section className="welcome-section">
                <h1 className="welcome-title">
                    Connect. Collaborate. <span className="highlighted-word">Grow.</span>
                </h1>
                <p className="welcome-description">
                    Find brand partnerships and collaborations for your YouTube channel
                </p>
                <div>
                    <button className="login-btn" onClick={handleClick}>
                        Login with Google
                    </button>
                    {isLoading && (
                        <Wrapper>
                            <PropagateLoader
                                color={'#818181'}
                                loading={isLoading}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </Wrapper>
                    )}
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div className="features-grid">
                        <div className="feature">
                            <h3>Smart Creator-Brand Matching</h3>
                            <p>Our advanced matching algorithm connects creators and brands based on their target audience, content preferences, and collaboration goals, ensuring successful partnerships that drive engagement and results.</p>
                        </div>
                        <div className="feature">
                            <h3>In-depth Analytics & Tracking</h3>
                            <p>Make data-driven decisions with our built-in analytics and performance tracking tools. Monitor the success of your collaborations, assess campaign effectiveness, and optimize your partnerships for maximum impact.</p>
                        </div>
                        <div className="feature">
                            <h3>User-friendly Dashboard & Chat</h3>
                            <p> Manage your partnerships effortlessly with our user-friendly dashboard and integrated communication tools. Access all essential information, communicate with your partners, and streamline the collaboration process in one convenient location.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
