import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './styles.css';

function CustomDialog({ openModal, onClose, children, width }) {
    return (
        <Dialog
            open={openModal}
            onClose={onClose}
            classes={{ paper: 'custom-dialog-paper' }} // Apply styles to the paper container
            PaperProps={{ style: { width: width ? width : '600px' , maxWidth: width ? width : '600px' } }}
        >
            <div className="dialog-header">
                <IconButton className="close-button" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className="dialog-content">
                {children}
            </div>
        </Dialog>
    );
}

export default CustomDialog;
