import React, {useContext, useEffect, useState} from 'react';

import '../styles.css';
import {Box, Button, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import {UserContext} from "../../../context/UserContext";
import SponsorshipsTable from "../../../components/Table";
import {FadeLoader, PropagateLoader, PulseLoader} from "react-spinners";
import {Wrapper} from "../../Home";
import EmptyState from "../../../assets/empty-state.svg"
import styled from "styled-components";
import VideoDetails from "../../../components/VideoDetails";
import {useApiClient} from "../../../hooks/useApiClient";
import {OverviewSponsors} from "../overview/overview-sponsors";
import {OverviewRevenue} from "../overview/overview-revenue";
import {OverviewGainedViews} from "../overview/overview-gained-views";
import CountUp from "react-countup";
import Chat from "../../../components/Chat";

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        height: '42px', // set the height
        padding: '0px 16px', // adjust padding as necessary
        fontSize: '14px', // adjust font size as necessary
        borderRadius: '0px 8px 8px 0px !important'

    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px 0px 0px 8px !important'
    },
});

const Badge = styled.span`
  display: inline-block;
  padding: 0.2em 1em;
  border-radius: 10px;
  color: white;
  width: 106px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  background-color: ${props => {
    if (props.status === 'pending') return '#f0ad4e';
    if (props.status === 'accepted') return '#5cb85c';
    if (props.status === 'rejected') return '#d9534f';
    if (props.status === 'in negotiation') return '#0275d8'; // Bootstrap's info color
    if (props.status === 'postponed') return '#5bc0de'; // Bootstrap's info color
}};
`;


const CreatorDashboard = () => {
    // Add your page implementation here
    const [videoUrl, setVideoUrl] = useState('');  // <-- state for storing the URL
    const [loadingSpons, setLoadingSpons] = useState(true);  // <-- state for storing the URL
    const [extractedVideo, setExtractedVideo] = useState(null);  // <-- state for storing the URL
    const [sponsorships, setSponsorships] = useState(null);  // <-- state for storing the URL
    const [hasSponsorships, setHasSponsorships] = useState(false);
    const [loadingVideoDetails, setLoadingVideoDetails] = useState(false);
    const [totalSponsoredViews, setTotalSponsoredViews] = useState(0);
    const [totalCurrentViews, setTotalCurrentViews] = useState(0);
    const [totalAmountOwed, setTotalAmountOwed] = useState(0);

    const { user } = useContext(UserContext);
    const apiClient = useApiClient();

    useEffect(()=>{
        fetchSponsorships()
    },[])

    const fetchSponsorships = async (noCache = false) => {

        try {
            const res = await apiClient.get(`/v2/creator-sponsorships?user_id=${user._id}&no_cache=${noCache}`);
            setLoadingSpons(false)
            if (res?.data) {
                if (sponsorships) {
                    const oldRowsMap = sponsorships?.reduce((acc, row) => {
                        acc[row._id] = row;
                        return acc;
                    }, {});

                    res.data.forEach(row => {
                        if (oldRowsMap[row._id]) {
                            row.previous_pay_per_view_rate = oldRowsMap[row._id].pay_per_view_rate;
                            row.previous_current_views_count = oldRowsMap[row._id].current_views_count;
                            row.previous_gained_views = oldRowsMap[row._id].gained_views;
                            row.previous_total_amount_owed = oldRowsMap[row._id].total_amount_owed;
                            row.previous_engagement_rate = oldRowsMap[row._id].engagement_rate * 100;
                        }
                    });
                }

                const totalSponsoredViewsSum = res.data.reduce((total, sponsorship) => {
                    return total + parseFloat(sponsorship.gained_views || 0);
                }, 0);

                const totalCurrentViewsSum = res.data.reduce((total, sponsorship) => {
                    return total + parseFloat(sponsorship.current_views_count || 0);
                }, 0);

                const totalAmountOwedSum = res.data.reduce((total, sponsorship) => {
                    return total + parseFloat(sponsorship.total_amount_owed || 0);
                }, 0);
                // Set state
                setTotalSponsoredViews(totalSponsoredViewsSum);
                setTotalCurrentViews(totalCurrentViewsSum);
                setTotalAmountOwed(totalAmountOwedSum);
                setSponsorships(res.data);
                setHasSponsorships(true) // Set hasSponsorships to true if sponsorships are not empty
            }
        } catch (err) {
            console.error(err);
            setExtractedVideo(null)
        }
    }

    useEffect(()=>{
        // Run fetchSponsorships immediately after component mounts
        fetchSponsorships(true);

        // If there are sponsorships, setup the interval
        if (hasSponsorships) {
            const intervalId = setInterval(() => fetchSponsorships(true), 16000); // Fetch every 60 seconds with parameter true

            // Cleanup function to clear the interval when component unmounts
            return () => clearInterval(intervalId);
        }
    }, [hasSponsorships]) // Rerun useEffect when hasSponsorships changes

    const extractVideoID = (url) => {
        let video_id = url.split('v=')[1];
        let ampersandPosition = video_id.indexOf('&');
        if (ampersandPosition != -1) {
            video_id = video_id.substring(0, ampersandPosition);
        }
        return video_id;
    }

    const handleGoClick = async () => {
        const id = extractVideoID(videoUrl)
        try {
            setLoadingVideoDetails(true)
            const res = await apiClient.get(`/getVideoData?video_id=${id}`);
            setLoadingVideoDetails(false)
            if (res?.data) {
                setExtractedVideo(res?.data)
            }
        } catch (err) {
            console.error(err);
        }
    }

    const fetchSponsorshipDetails = async () => {
        try {
            const res = await apiClient.get(`/fetch-sponsorship-details?user_id=${user._id}`);
            if (res?.data) {
                return(res?.data)
            }
        } catch (err) {
            console.error(err);
        }
    }

    const totalPreviousTotalAmountOwed = sponsorships?.reduce((total, sponsorship) => {
        return total + parseFloat(sponsorship.previous_total_amount_owed || 0);
    }, 0);

    if(loadingSpons){
        return <Wrapper><PropagateLoader
            color={'#818181'}
            loading={true}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
        </Wrapper>
    }

    const columns = [
        {
            field: "video_title",
            headerName: "Video",
            align: "left",
            component: "th",
            scope: "row",
        },
        {
            field: "brand_name",
            headerName: "Brand",
            align: "center",
        },
        {
            field: "status",
            headerName: "Status",
            align: "center",
            renderCell: (row) => <Badge status={row.status}>{row.status}</Badge>,
        },
        {
            field: "engagement_rate",
            headerName: "Engagement Rate",
            align: "center",
            renderCell: (row) => <CountUp prefix="%" start={row.previous_engagement_rate} end={row.engagement_rate * 100} />,
        },
        {
            field: "pay_per_view_rate",
            headerName: "CPM",
            align: "center",
            renderCell: (row) => <CountUp prefix="$" start={row.previous_pay_per_view_rate} end={row.pay_per_view_rate} />,
        },
        {
            field: "current_views_count",
            headerName: "Current Views",
            align: "center",
            renderCell: (row) => {

                return ( <CountUp key={row.current_views_count} start={row.previous_current_views_count} end={row.current_views_count} />)
            },
        },
        {
            field: "gained_views",
            headerName: "Sponsored Views",
            align: "center",
            renderCell: (row) => <CountUp   key={row.gained_views} // Will remount the component when gained_views changes
                                            start={row.previous_gained_views} end={totalSponsoredViews} />,
        },
        {
            field: "total_amount_owed",
            headerName: "Total Amount Owed",
            align: "center",
            renderCell: (row) => <CountUp prefix="$" start={row.previous_total_amount_owed} end={row.total_amount_owed} />,
        },
    ];

    return (
        <Box
            className="dashboard-container"
        >
                <Grid
                    container
                    spacing={2}
                    rowSpacing={4}
                >
                    <Grid
                        item
                        xs={3}
                        md={4}
                    >
                        <OverviewSponsors value={sponsorships?.length || 0}/>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        md={4}
                    >
                        <OverviewRevenue value={totalAmountOwed || 0}/>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        md={4}
                    >
                        <OverviewGainedViews value={totalSponsoredViews} sx={{height: '100%'}}/>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        md={12}
                    >
                        <Box mb={3}>
                            {loadingSpons
                                ? <PropagateLoader
                                    color={'#818181'} loading={true} size={20} />
                                : sponsorships && sponsorships.length > 0
                                    ?
                                    <SponsorshipsTable type="creator" rows={sponsorships} columns={columns} />
                                    : <Box className="empty-state">
                                        <img className="empty-img" src={EmptyState} />
                                        <Box className="empty-text">
                                            Currently you have no sponsorships
                                        </Box>
                                        <Box className="empty-subText">
                                            Paste the url of the video you want to get sponsorship on
                                        </Box>
                                        <Box mb={3}>
                                            {/*<Box className="section-title">Create New Sponsorship</Box>*/}
                                            <CustomTextField
                                                className="url-input"
                                                id="outlined-required"
                                                placeholder="Enter your video url"
                                                value={videoUrl}
                                                onChange={e => setVideoUrl(e.target.value)}
                                            />
                                            <Button onClick={handleGoClick} variant="contained" className="go-button">
                                                Get video
                                            </Button>
                                        </Box>
                                    </Box>
                            }
                        </Box>
                    </Grid>
       <Grid
           xs={9}
           md={12}
           item
       >
            <Box className="sponsorships-container">
                {
                    sponsorships.length > 0 &&
                    <Box mb={3}>
                        <Box sx={{mt: 2}} className="section-title">Track & Monitor Sponsorship</Box>
                        <Box sx={{mt: 2}} className="section-description">Monitor your sponsored video in real-time by associating it with a completed campaign ad. This setup allows for minute-by-minute tracking, delivering comprehensive KPIs, such as engagement rate, views, and watch time, directly to your dashboard. </Box>
                        <CustomTextField
                            className="url-input"
                            id="outlined-required"
                            placeholder="Enter your video url"
                            value={videoUrl}
                            onChange={e => setVideoUrl(e.target.value)}
                        />
                        <Button
                            onClick={handleGoClick}
                            variant="contained"
                            color="secondary"
                            className="go-button"
                            startIcon={<FadeLoader cssOverride={{
                                "top": "5px",
                                "left": "-4px",
                                "height":"0px",
                                "width":"0px"
                            }} margin={-8} height={5} width={3} speedMultiplier={3} color={'#fff'} loading={loadingVideoDetails} /> }
                        >
                            Get video
                        </Button>
                    </Box>
                }
                <Box className="video-container">
                    <VideoDetails video={extractedVideo} refresh={fetchSponsorships} fetchSponsorshipDetails={fetchSponsorshipDetails} />
                </Box>

            </Box>
       </Grid>
    </Grid>
        </Box>
    );
};
export default CreatorDashboard;