import {Button} from "@mui/material";
import "./styles.css"

const CustomButton = ({handler, children, ...props}) => {

    return (<Button onClick={handler} className="custom-button" {...props}>
        {children}
    </Button>)
}

export default CustomButton;
