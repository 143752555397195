import {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StatusMenu = ({ row, handleUpdateStatus }) => {
     const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleAction = (status) => {
            handleUpdateStatus(row._id, status);
            handleClose();
        }

        return (
            <div>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={() => handleAction('accepted')}
                    >
                        Accept
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleAction('in negotiation')}
                    >
                        Negotiate
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleAction('postponed')}
                    >
                        Postpone
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleAction('rejected')}
                    >
                        Reject
                    </MenuItem>
                </Menu>
            </div>
        );
    }
export default StatusMenu;