import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import {FormControl, FormLabel, Input, InputBase, TextField} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        marginTop: 8,
        position: 'relative',
        backgroundColor:'#FFF',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const NumericFormatAdapter = React.forwardRef(function NumericFormatAdapter(
    props,
    ref,
) {
    const { value,name,format, onChange,thousandSeparator = true, ...other } = props;

    return (
        <NumericFormat
            {...other}
            {...props}
            value={value}
            name={name}
            customInput={BootstrapInput}
            prefix={'$'}
            format={format || null}
            type="text"
            thousandSeparator={thousandSeparator ? ',' : null}
            onChange={(e) => e.preventDefault()} // prevent default onChange event
            onValueChange={(values) => {
                onChange({ target: { name, value: values.value } });
            }}
        />
    );
});

export default function NumericCurrencyInput({placeholder = "type...", handleChange}) {
    const [value, setValue] = React.useState(null);
    return (
        <FormControl>
            <NumericFormatAdapter
                value={value}
                name="feeAmount"
                onChange={handleChange}
                placeholder={placeholder}/>
        </FormControl>
    );
}