import React from 'react';
import {
    Box,
    Checkbox,
    CardContent,
    Typography,
    CardActionArea,
    Radio,
    RadioGroup,
    FormControlLabel
} from '@mui/material';
import './styles.css'
const AdvancedCardSelect = ({
                                title,
                                description,
                                image,
                                imageSelected,
                                selected,
                                onChange,
                                duration,
                                onDurationChange
}) => {
    return (
        <Box
            variant="outlined"
            sx={{
                border: '1px solid',
                borderColor: selected ? 'blue' : 'grey.300',
                backgroundColor: selected ? '#f0f8ff' : '#fff' }}
        >
            <CardActionArea disableRipple onClick={onChange}>
                <Box display="flex">
                    <Box flex={3}>
                        <CardContent>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6">{title}</Typography>
                            </Box>
                            <Typography variant="body2" color="textSecondary" mt={1}>
                                {description}
                            </Typography>
                        </CardContent>
                    </Box>
                    <Box flex={1} display="flex" justifyContent="center" alignItems="center">
                        <img src={selected ? imageSelected : image} alt={title} style={{ maxWidth: '100%' }} />
                    </Box>
                </Box>
            </CardActionArea>
            <Box className="card-footer">
                <Box>
                    Duration
                </Box>
                <RadioGroup className="radio-group" row value={duration} onChange={onDurationChange}>
                    <FormControlLabel value="15" control={<Radio />} label="15s" />
                    <FormControlLabel value="30" control={<Radio />} label="30s" />
                    <FormControlLabel value="60" control={<Radio />} label="60s" />
                </RadioGroup>
            </Box>
        </Box>
    );
};

export default AdvancedCardSelect;
