import {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Unstable_Grid2 as Grid
} from '@mui/material';

const socials = [
    {
        value: 'youtube',
        label: 'Youtube'
    },
    {
        value: 'Tiktok',
        label: 'TikTok'
    },
    {
        value: 'Meta',
        label: 'Meta'
    },

];

export const BrandProfileDetails = ({details}) => {
    const [values, setValues] = useState({
        contactPerson: '',
        contactEmail: '',
        contactPhoneNumber: '',
        socialMediaHandles: null,
        companyAddress: '',
        email: '',
    });
    useEffect(() => {
        setValues({
            contactPerson: details?.contactPerson || '',
            contactEmail: details?.contactEmail || '',
            contactPhoneNumber: details?.contactPhoneNumber || '',
            socialMediaHandles: details?.socialMediaHandles || null,
            companyAddress: details?.companyAddress || '',
            email: details?.email || '',
        });
    }, [details]);

    const handleChange = useCallback(
        (event) => {
            setValues((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }));
        },
        []
    );

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
        },
        []
    );

    return (
        <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
        >
            <Card>
                <CardHeader
                    subheader="The information can be edited"
                    title="Profile"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    helperText="Please specify your name"
                                    label="Contact Name"
                                    name="contactPerson"
                                    onChange={handleChange}
                                    required
                                    value={values.contactPerson}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Contact Email"
                                    name="contactEmail"
                                    onChange={handleChange}
                                    required
                                    value={values.contactEmail}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Contact Phone"
                                    name="contactPhoneNumber"
                                    onChange={handleChange}
                                    required
                                    value={values.contactPhoneNumber}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Owner Email"
                                    disabled
                                    name="email"
                                    onChange={handleChange}
                                    value={values.email}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Company Address"
                                    name="companyAddress"
                                    onChange={handleChange}
                                    required
                                    value={values.companyAddress}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Social Media Platform"
                                    name="socialMediaHandles"
                                    onChange={handleChange}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={values.socialMediaHandles}
                                >
                                    {socials.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button variant="contained">
                        Save details
                    </Button>
                </CardActions>
            </Card>
        </form>
    );
};