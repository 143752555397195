import {Outlet, useLocation} from 'react-router-dom';
import {useState} from "react";
import {Box} from "@mui/material";
import "./styles.css"
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useCampaign} from "../../../context/CampaignContext";

const steps = [
    'Details',
    'Adset',
    'Preview',
];

const CampaignSteps = ({ activeStep }) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}


const CampaignCreator = () => {
    const { campaignData } = useCampaign();
    const location = useLocation();

    // Determine the active step based on the path name
    const getActiveStep = () => {
        const path = location.pathname;
        if (path.includes('details')) {
            return 0;
        }
        if (path.includes('adset')) {
            return 1;
        }
        if (path.includes('preview')) {
            return 2;
        }
        return 0;
    };

    // Store the active step
    const activeStep = getActiveStep();


    return (
        <Box>
            {/* Render the current step component */}
            <Box className="campaign-creator-header">
               <Box className="campaign-creator-title">
                   Create Campaign
               </Box>
                <CampaignSteps activeStep={activeStep} />
            </Box>
            <Outlet />
        </Box>
    );
}

export default CampaignCreator;
