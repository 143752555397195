import {Avatar, Box, Button, Checkbox, SvgIcon} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import EmptyState from "../../assets/empty-state.svg";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import {useApiClient} from "../../hooks/useApiClient";
import {UserContext} from "../../context/UserContext";
import {PropagateLoader} from "react-spinners";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {ReactComponent as BudgetIcon} from "../../assets/icon_money_bag.svg";
import {ReactComponent as RocketAd} from "../../assets/rocket-ads.svg";
import {INTEGRATION_TYPES, SEGMENTS} from "../Campaigns";
import {CloseOutlined, Delete, Remove} from "@mui/icons-material";
import CustomDialog from "../../components/Dialog";
import RemoveHireIcon from "../../assets/remove-hire.svg";
import {useHire} from "../../context/HiresContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const getAdTypeInfo = (adTypeTitle) => {
    // Combine SEGMENTS and INTEGRATION_TYPES for easy lookup
    const allAdTypes = [...SEGMENTS, ...INTEGRATION_TYPES];
    return allAdTypes.find(ad => ad.title === adTypeTitle) || {};
}

export const AdTypesCarousel = ({ ads }) => {
// Slider settings
    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        centerPadding: '10px',  // or whatever gap size you'd like
        slidesToScroll: 3,
    };
    const adTypeEntries = Object.entries(ads).filter(([_, value]) => value !== false);

    return (
                <Slider {...settings}>
                {adTypeEntries.map(([adTypeTitle, adTypeInfo], index) => {
                    const { image, description } = getAdTypeInfo(adTypeTitle);
                    return (
                        <Box key={index} className="ad-type-item-container">
                            <img className="ad-type-image" src={image} alt={adTypeTitle} />
                            <Box className="ad-type-title">{adTypeTitle}</Box>
                            <Box className="ad-type-amount">${adTypeInfo.amount?.toLocaleString()}</Box>
                        </Box>
                    );
                })}
                </Slider>
    );
}

export const HireCard = ({hire, handleRemoveHire,selectedHires, handleCheckboxChange}) => {
    //it should contain the creator name, profile image, and the total amount to be paid

    const adsCount = Object.keys(hire?.selected_ad_types).length

    return <Box className={`hire-card ${selectedHires.includes(hire._id) ? 'selected' : ''}`}>
        <Box className="hire-card-header">
            <Box className="hire-card-header-left">
                <Avatar sx={{width: '40px', height: '40px'}} src={hire?.creator_details.logo} alt={hire.creator_details.name} />
                <Box className="hire-card-header-left-text">
                    <Box className="hire-card-header-left-text-name">
                        {hire.creator_details.name}
                    </Box>
                </Box>
                {/*<OpenInNewIcon*/}
                {/*    color="primary"*/}
                {/*    sx={{cursor: 'pointer'}}*/}
                {/*    onClick={() => window.open(hire.creator_details.channelUrl, '_blank')}*/}
                {/*/>*/}
            </Box>
            <Checkbox
                checked={selectedHires.includes(hire._id)}
                onChange={() => handleCheckboxChange(hire._id)}
            />
        </Box>
        <Box className="hire-numbers-container" >
            <div className="hire-icon-wrapper">
                <SvgIcon className="feed-icon" component={BudgetIcon} inheritViewBox/>
                <div className="icon-text" variant="caption">
                    <div className="icon-text-title">Total Amount</div>
                    <div className="icon-text-value">${hire?.total_amount?.toLocaleString()}</div>
                </div>
            </div>
            <div className="hire-icon-wrapper">
                <SvgIcon className="feed-icon" component={RocketAd} inheritViewBox/>
                <div className="icon-text" variant="caption">
                    <div className="icon-text-title">Ads count</div>
                    <div className="icon-text-value">{adsCount}</div>
                </div>
            </div>
        </Box>
        <Box sx={{width: '100%', padding: 3}}>
            <AdTypesCarousel ads={hire?.selected_ad_types} />
        </Box>
        <Button
            onClick={()=>handleRemoveHire(hire._id)}
            sx={{
                marginLeft: 4,
                width: '100px',
                marginTop: '20px',
                borderRadius: 0,
                background: "#000",
                "&:hover": {
                    backgroundColor: "#333", // A slightly lighter shade on hover
                    transition: "background-color 0.3s ease"
                }
            }}
            variant="contained"
            endIcon={<Delete />}
        >
            Remove
        </Button>
    </Box>

}

const Hires = (props) => {
    const navigate = useNavigate();
    const apiClient = useApiClient();
    const { user } = useContext(UserContext);
    const [hireIdToDelete, setHireIdToDelete] = useState();
    const { hires,
        setHires,
        loading,
        fetchHires,
        handleRemoveHire,
        selectedHires,
        setSelectedHires,
        handleCheckboxChange } = useHire();


    useEffect(() => {
        const allHireIds = hires.map(hire => hire._id);
        setSelectedHires(allHireIds);
    }, [hires]);
    const handleRemoveHireModal = (hireId) => {
        setHireIdToDelete(hireId);
    }

    const handleDeleteHire = () => {
        handleRemoveHire(hireIdToDelete);
        setHireIdToDelete(null);
    }

    return (<Box className="hires-page">
        {
            loading ? <Box className="empty-state">
                <Box className="empty-text">
                    <PropagateLoader
                        color={'#818181'}
                        loading={loading}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </Box>
            </Box> : (hires.length > 0 ? <Box className="hire-page-container">
                    <Box className="page-title">
                        Fund Hires ({hires.length})
                    </Box>
                    <Box className="info-description">
                        This page lists all creators you've chosen for collaboration.
                        To proceed with the content creation phase in the collaborative workspace, please ensure each recruit is funded accordingly.
                    </Box>
                    <Box className="hire-card-list-wrapper">
                        {
                            hires.map((hire, index) => {
                                return <HireCard
                                    handleCheckboxChange={handleCheckboxChange}
                                    key={index}
                                    hire={hire}
                                    selectedHires={selectedHires}
                                    handleRemoveHire={handleRemoveHireModal}
                                    onRefresh={fetchHires}
                                />
                            })
                        }
                    </Box>
                    <Box className="next-button-wrapper">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={selectedHires?.length === 0}
                            sx={{width: '200px', marginTop: '20px', borderRadius: 0}}
                            onClick={() => navigate('/hires/fund')}
                        >
                            Next {!!selectedHires?.length && `(${selectedHires?.length})`}
                        </Button>
                    </Box>
                    <CustomDialog openModal={!!hireIdToDelete} onClose={() => setHireIdToDelete(null)}>
                        <Box className="modal-campaign-container">
                            <Box className="modal-campaign-title">
                                Are you sure you want to remove this hire?
                            </Box>
                            <img src={RemoveHireIcon} className="modal-campaign-img" />

                            <Box sx={{display: 'flex', alignItems: 'center', gap:'24px', marginTop: 3}}>
                                <Button variant="outlined" onClick={() => setHireIdToDelete(null)}>
                                    Cancel
                                </Button>
                                <Button variant="contained" onClick={handleDeleteHire}>
                                    Remove
                                </Button>
                            </Box>
                        </Box>
                    </CustomDialog>
                </Box>:
                <Box className="empty-state">
                    <img className="empty-img" src={EmptyState} />
                    <Box className="empty-text">
                        Currently you have no new hires.
                    </Box>
                </Box>)
        }
    </Box>);
}

export default Hires;