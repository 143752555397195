// useGoogleSignIn.js

import { useContext } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {reauthenticateWithCredential} from "@firebase/auth";

export const useGoogleSignIn = () => {
    const navigate = useNavigate();
    const { setUser, setFbUser} = useContext(UserContext);
    const provider = new GoogleAuthProvider();

    provider.addScope('https://www.googleapis.com/auth/youtube.readonly');
    provider.addScope('https://www.googleapis.com/auth/yt-analytics.readonly');
    provider.addScope('https://www.googleapis.com/auth/yt-analytics-monetary.readonly');
    // provider.addScope('https://www.googleapis.com/auth/youtube.force-ssl');

    const signIn = async () => {
        const auth = getAuth();
        try {
            const result = await signInWithPopup(auth, provider);

            // The signed-in user info.
            const user = result.user;

            const credential = GoogleAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const now = new Date();
            const expiresIn = 3550;
            const expiresAt = now.getTime() + expiresIn * 1000; // Convert expiresIn from seconds to milliseconds
            localStorage.setItem('tokenExpiresAt', expiresAt);
            localStorage.setItem('accessToken', accessToken);
            setFbUser(result.user);
            setUser(result.user);
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/user-check/${result.user.email}`);

            if (res?.data?.status === "failure") {

                setTimeout(() => {
                    navigate('/register', { replace: true, state: { fromLogin: true } });
                }, 500);
            } else {
                const auth_token = res.data.auth_token;
                // Store the auth_token in localStorage
                localStorage.setItem('auth_token', auth_token);
                navigate(res?.data?.user.type === "creator" ? '/creator-profile' : '/brand-profile');
            }

        } catch (error) {
            // Handle errors here.
            console.log(error);
        }
    };

    return { signIn };
};
