import { useState } from 'react';
import { Box } from "@mui/material";
import MultipleSelectChip from "../MultiSelect";
import './styles.css';
import {useCampaign} from "../../context/CampaignContext";

const LOCATIONS = ["United States", "Canada", "United Kingdom"];
const GENDERS = ["No Limit", "Male", "Female"];
const AGE = ["No Limit", "13-17", "18-24", "25-34", "35-44", "45-54", "55+"];

const Audience = () => {

    const { campaignData, setCampaignData } = useCampaign();

    const handleGenderClick = (gender) => () => {

        setCampaignData(prevData => ({
            ...prevData,
            adSet: {
                ...prevData.adSet,
                gender: gender
            }
        }));
    };

    const handleAgeClick = (age) => () => {
        setCampaignData(prevData => {
            // If the selected age is "No Limit"
            if (age === "No Limit") {
                return {
                    ...prevData,
                    adSet: {
                        ...prevData.adSet,
                        age: ["No Limit"]
                    }
                };
            }

            // If "No Limit" is already in the age array and a different age is clicked
            const filteredAges = prevData.adSet.age.includes("No Limit")
                ? prevData.adSet.age.filter(a => a !== "No Limit")
                : prevData.adSet.age;

            return {
                ...prevData,
                adSet: {
                    ...prevData.adSet,
                    age: filteredAges.includes(age)
                        ? filteredAges.filter(a => a !== age)
                        : [...filteredAges, age]
                }
            };
        });
    };


    const handleLocationChange = (e) => {
        const { name, value } = e.target;
        setCampaignData(prevData => ({
            ...prevData,
            adSet: {
                ...prevData.adSet,
                [name]: value
            }
        }));
    }

    return (
        <Box>
            <MultipleSelectChip
                placeholder="Select Location"
                categories={LOCATIONS}
                name="locations"
                handleChange={handleLocationChange}
            />
            <Box>
                <Box className="subtitle">
                    Gender
                </Box>
                <Box className="button-wrapper">
                    {
                        GENDERS.map(gen => (
                            <Box
                                key={gen}
                                className={`selection-button ${gen === campaignData.adSet.gender? 'selected' : ''}`}
                                onClick={handleGenderClick(gen)}
                            >
                                {gen}
                            </Box>
                        ))
                    }
                </Box>
            </Box>
            <Box>
                <Box className="subtitle">
                    Age
                </Box>
                <Box className="button-wrapper">
                    {
                        AGE.map(a => (
                            <Box
                                key={a}
                                className={`selection-button age-button ${campaignData.adSet.age.includes(a) ? 'selected' : ''}`}
                                onClick={handleAgeClick(a)}
                            >
                                {a}
                            </Box>
                        ))
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default Audience;
