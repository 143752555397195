import { createContext, useContext, useState } from 'react';

const CampaignContext = createContext();

export const useCampaign = () => useContext(CampaignContext);

export const CampaignProvider = ({ children }) => {
    const [campaignData, setCampaignData] = useState({
        details: {
            title: '',
            brandURL: '',
            attributionSetup: '',
            description: '',
            budget: 'Under $1,000',
            creatorCategories: [],
            campaignDuration: '7 days'
        },
        adSet: {
            age: ["No Limit"],
            gender: "No Limit",
            integrationTypes: {

            },
            promoSegments: {

            }
        },
    });
    console.log('on campaign provider')

    return (
        <CampaignContext.Provider value={{ campaignData, setCampaignData }}>
            {children}
        </CampaignContext.Provider>
    );
};
