import { useContext, useMemo } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import {UserContext} from "../context/UserContext";
import jwtDecode from "jwt-decode";

const getAccessToken = () => localStorage.getItem('accessToken');
const getAuthToken = () => localStorage.getItem('auth_token');

const isTokenExpired = () => {
    const now = new Date();
    const expiresAt = localStorage.getItem('tokenExpiresAt');
    return now.getTime() > expiresAt
};

export const useApiClient = () => {
    const { user, handleLogout } = useContext(UserContext);

    const apiClient = useMemo(() => {
        const instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                'Authorization': '',
                'auth_token': '',
            },
        });

        instance.interceptors.request.use(async (config) => {
            const AccessToken = getAccessToken();
            const AuthToken = getAuthToken();

            if (isTokenExpired()) {
                handleLogout();
                throw new Error('Token has expired');
            }

            config.headers['Authorization'] = `Bearer ${AccessToken}`;
            config.headers['authToken'] = `Bearer ${AuthToken}`;
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        return instance;
    }, [user]);

    return apiClient;
};