import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import {useEffect} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '540px',
        },
    },
};

function getStyles(name, selectedCategories, theme) {
    return {
        fontWeight:
            selectedCategories.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectChip({categories, handleChange, placeholder = "Select Category", ...rest}) {
    const theme = useTheme();
    const [selectedCategories, setSelectedCategories] = React.useState([]);


    useEffect(()=>{
        // Now, we create an event object to fit the structure your handleChange function expects
        const customEvent = {
            target: {
                name: rest.name,  // Assuming this is the name of the field in CampaignDetails
                value: selectedCategories
            }
        };

        // Call the handleChange passed down as a prop
        handleChange(customEvent);
    },[selectedCategories])
    const handleSelectFormat = (event) => {
        const {
            target: { value },
        } = event;

        // Determine the new selected categories
        const newSelectedCategories = typeof value === 'string' ? value.split(',') : value;

        setSelectedCategories(newSelectedCategories);
    };


    const handleDelete = (event: React.MouseEvent, valueToDelete: string) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedCategories((prev) => prev.filter((value) => value !== valueToDelete));

    };



    return (
        <div>
            <FormControl sx={{ width: '100%' }}>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    displayEmpty
                    value={selectedCategories}
                    onChange={handleSelectFormat}
                    input={<OutlinedInput
                        id="select-multiple-chip"
                        placeholder={selectedCategories.length ? '' : placeholder}
                    />}
                    renderValue={(selected) => (
                        selected.length === 0 ? (
                            <em>{placeholder}</em>
                        ) : (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Box key={value} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                        <Chip
                                            key={value}
                                            label={value}
                                            clickable
                                            deleteIcon={
                                                <CloseIcon
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                />
                                            }
                                            onDelete={(e) => handleDelete(e, value)}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        )
                    )}
                    MenuProps={MenuProps}
                    {...rest}
                >
                    <MenuItem disabled value="">
                        <em>{placeholder}</em>
                    </MenuItem>
                    {categories.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, selectedCategories, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
