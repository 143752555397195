import React from 'react';
import AppRoutes from './AppRoutes';
import { SnackbarProvider } from 'notistack';

import { initializeApp } from "firebase/app";
import {createTheme} from "./theme";
import {CssBaseline, ThemeProvider} from "@mui/material";

const firebaseConfig = {
    apiKey: "AIzaSyC1SFVxkGpr6iVRH2m4GfH29yyysZlwoMo",
    authDomain: "sponsorship-platform-4d5ea.firebaseapp.com",
    projectId: "sponsorship-platform-4d5ea",
    storageBucket: "sponsorship-platform-4d5ea.appspot.com",
    messagingSenderId: "35678164167",
    appId: "1:35678164167:web:f7e7c0815658cec27bdae2",
    measurementId: "G-KPE97KN965"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const theme = createTheme();

function App() {
    console.log('on app')

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
        <div className="App">
            <AppRoutes />
        </div>
            </SnackbarProvider>
        </ThemeProvider>

    );
}

export default App;