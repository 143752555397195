import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

export default function ValidationBehaviorView({value, onChange, fixed=true}) {

    const handleDateChange = (newValue) => {
        onChange(newValue)
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <DateCalendar
                orientation="portrait"
                disablePast
                value={value}
                onChange={handleDateChange}
                showDaysOutsideCurrentMonth={true}
                sx={{width: fixed ? '540px' : '100%',
                padding: '14px',
                border: '1px solid rgb(242, 242, 244)',
                borderRadius: '4px',
                '& .MuiDayCalendar-weekContainer': {
                    // your styles here, for example:
                    justifyContent: 'space-between',
                },
                '& .MuiDayCalendar-header': {
                    // your styles here, for example:
                    justifyContent: 'space-between',
                }}}/>
        </LocalizationProvider>
    );
}