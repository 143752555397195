import {useCampaign} from "../../../../context/CampaignContext";
import {Box, Divider} from "@mui/material";
import {INTEGRATION_TYPES, SEGMENTS} from "../../index";
import Button from "../../../../components/Button";
import dayjs from 'dayjs'
import {useNavigate} from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from "axios";
import {useApiClient} from "../../../../hooks/useApiClient";
import {useContext, useState} from "react";
import {UserContext} from "../../../../context/UserContext";
import CustomDialog from "../../../../components/Dialog";
import CampaignLaunch from "./../../../../assets/campaign-launch.svg"
import CampaignError from "./../../../../assets/campaign-error.svg"

const CampaignPreviewField = ({fieldKey, value, valueColor="#262843", valueClickHandler}) => {

        return ( <Box className="campaign-preview-field">
                <Box className="preview-field-key">
                        {fieldKey}
                </Box>
                <Box onClick={valueClickHandler} className="preview-field-value" sx={{
                        color: value === 'Not Selected' ? 'red' : valueColor,
                        cursor: valueClickHandler ? 'pointer' : 'default',
                        "&:hover": {
                                textDecoration: valueClickHandler ? 'underline' : 'none',
                        },
                }}>
                        {value}
                </Box>
        </Box>)
}
const Preview = () => {
        const navigate = useNavigate();
    const { campaignData } = useCampaign();
    const { user } = useContext(UserContext);
    const [campaignCreated, setCampaignCreated] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [errorModal, setErrorModal] = useState(undefined);

    const apiClient = useApiClient();

        const handleCreate = async () => {
            try {
                    const res = await apiClient.post(
                        `${process.env.REACT_APP_API_URL}/create-campaign`,
                        { ...campaignData, brandId: user._id }
                    );

                setCampaignCreated(true)
                setOpenModal(true)
            } catch (error) {
                    console.error(error);
                setErrorModal(error?.response?.data?.error)
            }
    }

    return (<Box className="campaign-creator-preview">
            <Box className="campaign-preview-title">
                    <Box className="title-text">
                            Details
                    </Box>
                    <Button variant="outlined" onClick={()=> (navigate('/campaign/create/details'))}>
                            Edit
                    </Button>
            </Box>

            <CampaignPreviewField fieldKey="Campaign Title:" value={campaignData.details.title}/>

            <CampaignPreviewField
                valueColor="#ff5722"
                fieldKey="Brand URL:" value={campaignData.details.brandURL}
                valueClickHandler={()=>window.open(`${campaignData.details.brandURL}`, '_blank')}
            />

            <CampaignPreviewField fieldKey="Attribution Setup:" value={campaignData.details.attributionSetup}/>

            <CampaignPreviewField fieldKey="Campaign Description:" value={campaignData.details.description}/>

            <CampaignPreviewField fieldKey="Creator Categories:" value={campaignData.details.creatorCategories.join(', ')}/>

            <CampaignPreviewField fieldKey="Campaign Duration:" value={campaignData.details.campaignDuration}/>
            <Divider />
            <Box className="campaign-preview-title">Adset</Box>
            <Box className="campaign-preview-subtitle">Integration Types</Box>
            <ul>
                {Object.entries(campaignData.adSet.integrationTypes).map(([title, isSelected]) => (
                    <CampaignPreviewField
                        valueColor="#26932a"
                        key={title}
                        fieldKey={title}
                        value={isSelected ? 'Selected' : 'Not Selected'}
                    />
                ))}
            </ul>


        <Box className="campaign-preview-subtitle">Promotion Segments</Box>

        <ul>
            {Object.entries(campaignData.adSet.promoSegments).map(([title, segment], index) => (
                <CampaignPreviewField
                    valueColor="#26932a"
                    key={index}
                    fieldKey={title}
                    value={segment.selected ? 'Selected' : 'Not Selected'}/>
            ))}
        </ul>

        <CampaignPreviewField
                fieldKey="Video Publishing Deadline:"
                value={dayjs(campaignData.adSet.videoPublishingDeadline).format('MM-DD-YYYY')}/>

            <Button
                className="next-button-campaign"
                variant="contained"
                color="primary"
                onClick={handleCreate}
                endIcon={<KeyboardArrowRightIcon />}>
                    Create Campaign
            </Button>
        <CustomDialog openModal={openModal} onClose={() => setOpenModal(false)}>
            <Box className="modal-campaign-container">
                <img src={CampaignLaunch} className="modal-campaign-img" />
                <Box className="modal-campaign-title">
                    Campaign created successfully!
                </Box>
                <Box className="modal-campaign-description">
                    Your campaign was successfully created and moved to
                    Campaign Management page.
                </Box>
                <Button variant="contained" onClick={()=> (navigate('/campaigns'))}>
                    Visit All Campaigns
                </Button>
            </Box>
        </CustomDialog>
        <CustomDialog openModal={!!errorModal} onClose={() => setErrorModal(undefined)}>
            <Box className="modal-campaign-container">
                <img src={CampaignError} className="modal-campaign-img" />
                <Box className="modal-campaign-title">
                    Campaign Creation Failed!
                </Box>
                <Box className="modal-campaign-description">
                    {errorModal}
                </Box>
                <Button variant="contained" onClick={()=> (navigate('/campaign/create/details'))}>
                    Fix Campaign
                </Button>
            </Box>
        </CustomDialog>
    </Box>)

}

export default Preview;