import React, {display, useContext, useEffect, useState} from 'react';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    Box,
    styled,
    Container, Slide, Zoom
} from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Pie } from 'react-chartjs-2';
import {Chart, ArcElement, PieController, Tooltip, Legend } from 'chart.js';
import {BeatLoader, PropagateLoader, PulseLoader} from "react-spinners";
import {Wrapper} from "../Home";
import {UserContext} from "../../context/UserContext";
import {Info} from "@mui/icons-material";
import {Tooltip as MuiTooltip} from "@mui/material";
import ErrorIcon from "./../../assets/error_page.svg"
import CustomDialog from "../../components/Dialog";
export const CategoryButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    textTransform: 'none',
    pointerEvents: 'none',
    fontWeight: theme.typography.fontWeightMedium,
}));

Chart.register(ArcElement, PieController, Tooltip, Legend);

function formatNumber(n) {
    if (n >= 1_000_000_000) {
        return `${(n / 1_000_000_000).toFixed(1)}B`; // Format to one decimal place and append "B"
    } else if (n >= 1_000_000) {
        return `${(n / 1_000_000).toFixed(1)}M`; // Format to one decimal place and append "M"
    } else if (n >= 10_000) {
        return `${(n / 1_000).toFixed(1)}k`; // Format to one decimal place and append "k"
    }
    return n.toLocaleString(undefined, { maximumFractionDigits: 2 });
}
const CreatorProfile = () => {
    const [creator, setCreator] = useState(null);
    const { user, loading , refreshingChannelDetails, error, handleLogout} = useContext(UserContext);

    useEffect(()=>{
        if(user){
            setCreator(user.channelDetails)
        }
    },[user])

    const demographicsData = {
        labels: creator?.demographics?.map((d) => d.label),
        datasets: [
            {
                data: creator?.demographics?.map((d) => d.value),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
                hoverOffset: 4,
            },
        ],
    };

    const chartOptions = {
        maintainAspectRatio: true,
        aspectRatio: 4,
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    const DemographicsCard = styled(Card)(({ theme }) => ({
        padding: theme.spacing(2),
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        boxShadow: 'none'
    }));

    const StyledCard = styled(Card)(({ theme }) => ({
        backgroundColor: 'inherit',
        padding: theme.spacing(2),
        boxShadow: 'none !important',
        border: 'none',
    }));

    const ValueCircle = styled(Box)(({ theme }) => ({
        width: '140px',  // Adjust the size as needed
        height: '140px',
        borderRadius: '50%',  // Make it a circle
        border: '6px solid #dedede',  // Red border with 4px width
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  // Center the content inside
        fontSize: '28px',  // Adjust font size as needed
        fontWeight: 500,
    }));

    const StyledRefreshBar = styled(Card)(({ theme }) => ({
        backgroundColor: '#d32f2fb8',
        color: '#fff',
        width: '70%',
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
        boxShadow: 'none',
        border: 'none',
    }));

    const StyledRefreshBarText = styled(Box)(({ theme }) => ({
        marginRight: '8px',
        fontWeight: 500,
    }));

    const StatCard = styled(Card)(({ theme }) => ({
        padding: theme.spacing(2),
        boxShadow: `none !important`,
        backgroundImage: 'repeating-linear-gradient(0deg,#e9eaec,#e9eaec 10px,transparent 0,transparent 20px,#e9eaec 0),repeating-linear-gradient(90deg,#e9eaec,#e9eaec 10px,transparent 0,transparent 20px,#e9eaec 0),repeating-linear-gradient(#e9eaec,#e9eaec 10px,transparent 0,transparent 20px,#e9eaec 0),repeating-linear-gradient(270deg,#e9eaec,#e9eaec 10px,transparent 0,transparent 20px,#e9eaec 0)',
        backgroundPosition: '0 0,0 0,100% 0,0 100% !important',
        backgroundRepeat: 'no-repeat !important',
        backgroundSize: '1px 100%,100% 1px,1px 100%,100% 1px',
        borderRadius: 0,
    }));

    const StatLabel = styled(Typography)(({ theme }) => ({
        fontSize: '16px',
        fontWeight: 500,
        textAlign: 'left',
        color: '#000',
        marginBottom: theme.spacing(3),
    }));

    const StatValue = styled(Typography)(({ theme }) => ({
        fontSize: '24px',
        fontWeight: 600,
    }));

    const PageContainer = styled(Container)(({ theme }) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        background: 'inherit'
    }));

    return (
        <PageContainer maxWidth="lg">
            { refreshingChannelDetails &&
                <Box sx={{background: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Zoom direction="down" in={refreshingChannelDetails}>
                    <StyledRefreshBar>
                        <StyledRefreshBarText>Performing real-time analysis with your latest channel
                            data</StyledRefreshBarText>
                        <BeatLoader
                            color={'#fff'}
                            loading={true}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"/>
                    </StyledRefreshBar>
                </Zoom>

            </Box>}

            {
                (refreshingChannelDetails || !creator) ? (error ?
                    <CustomDialog openModal={error} onClose={() => console.log("cannot close, please logout")}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap:4, alignItems: 'center', justifyContent: "center", width: '100%'}}>
                        <img style={{width: '300px'}} src={ErrorIcon} />
                        <Box sx={{marginLeft: '16px', fontSize:15, fontWeight:500}}>{error?.response?.data?.message || "Something went wrong, please contact support"}</Box>
                        <Button variant="outlined" onClick={handleLogout}>
                            Try different account
                        </Button>
                        </Box>
                    </CustomDialog>
                        :
                    <Wrapper> <PropagateLoader
                        color={'#818181'}
                        loading={true}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    </Wrapper> ) // If loading or creator is null, show the loader
                  :
                <StyledCard>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={2}>
                                <Avatar
                                    sx={{ width: '100px', height: '100px', margin: '0 auto' }}
                                    src={creator?.logo}
                                    alt={`${creator.name}'s logo`}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <Typography variant="h4">{creator.name}</Typography>
                                <Typography variant="subtitle1" gutterBottom>{creator.bio}</Typography>
                                <Box display="flex" alignItems="center" mb={2}>
                                    <YouTubeIcon color="error" />
                                    <Button href={creator.channelUrl} target="_blank" rel="noopener noreferrer" color="error" sx={{ textTransform: 'none', marginLeft: '4px' }}>
                                        YouTube
                                    </Button>
                                    <Grid container sx={{marginLeft: '106px'}} display="flex" gap="8px">
                                        {creator?.commonVideoCategories?.map((category, index) => (
                                            <Grid item key={index}>
                                                <Box key={index}>
                                                    <Box sx={{padding: 0.5, border: '1px solid #e8e8e8' ,fontSize: 12}}>{`${category[0]} (${category[1] === 50 ? '50+' : category[1]})`}</Box>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            {creator?.stats?.map((stat, index) => (
                                <Grid item xs={6} sm={3} key={index}>
                                    <StatCard>
                                        <StatLabel>{stat.label}</StatLabel>
                                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "center", paddingBottom: '34px'}}>
                                            <MuiTooltip
                                                title={stat.value.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                placement="top">
                                                <ValueCircle>
                                                {formatNumber(stat.value)}
                                                {stat.unit && <span>{stat.unit}</span>}
                                            </ValueCircle>
                                            </MuiTooltip>

                                        </Box>
                                    </StatCard>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <DemographicsCard>
                                    <Typography variant="h6" mb={2}>
                                        Audience Demographics
                                    </Typography>
                                    <Pie data={demographicsData} options={chartOptions} />
                                </DemographicsCard>
                            </Grid>
                        </Grid>
                    </CardContent>
                </StyledCard>

            }

        </PageContainer>
    );
};

export default CreatorProfile;
