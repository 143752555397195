import React, {useEffect, useState, useMemo, useContext} from "react";
import SBConversation from '@sendbird/uikit-react/Channel'
import SBChannelList from '@sendbird/uikit-react/ChannelList'
import { useDropzone } from 'react-dropzone';
import './styles.css'
import {Box, Divider, TextField, Tooltip} from "@mui/material";
import Button from "../Button";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import {sendbirdSelectors} from "@sendbird/uikit-react";
import {useApiClient} from "../../hooks/useApiClient";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Advertise from "../../assets/advertise.svg";
import UploadIcon from "../../assets/upload.svg";
import CustomDialog from "../Dialog";
import DeleteIcon from '@mui/icons-material/Delete';

import {INTEGRATION_TYPES, SEGMENTS} from "../../pages/Campaigns";
import {UserContext} from "../../context/UserContext";
import {FadeLoader} from "react-spinners";
import {
    Check,
    CheckCircleOutline, CurrencyExchange,
    ErrorOutline, MonetizationOnOutlined, MoneyRounded, TaskAltOutlined,
} from "@mui/icons-material";
import {SendingStatus} from "@sendbird/chat/message";
import dayjs from "dayjs";
import CustomTextField from "../CustomTextField";
import {AdTypesSmallCard} from "../AdTypesSmallCard";


export const getStatusColor = (status) => {
    switch (status) {
        case 'approved':
            return '#a5d6a7';
        case 'under_review':
            return '#D1C4E9';
        case 'request_changes':
            return '#FFCC80';
        default:
            return '#BBDEFB';
    }
};

export const getStatusText = (status) => {
    switch (status) {
        case 'approved':
            return 'Completed';
        case 'under_review':
            return 'Under Review';
        case 'request_changes':
            return 'Changes Requested';
        default:
            return 'Not Submitted';
    }
};

const Chat = () => {

    const { user } = useContext(UserContext);
    const apiClient = useApiClient();
    const globalStore = useSendbirdStateContext();

    const [currentChannelUrl, setCurrentChannelUrl] = useState('')
    const [channel, setChannel] = useState({})
    const [hireDetails, setHireDetails] = useState(null);
    const [selectedAdType, setSelectedAdType] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [requestChangesModal, setRequestChangesModal] = useState(false);
    const [uploadStep, setUploadStep] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null); // to store the uploaded file
    const [uploading, setUploading] = useState(false); // to store the uploaded file
    const [requestChangesText, setRequestChangesText] = useState(""); // to store the uploaded file
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'video/mp4',
        onDrop: acceptedFiles => {
            setUploadedFile(Object.assign(acceptedFiles[0], {
                preview: URL.createObjectURL(acceptedFiles[0])
            }));
        }
    });

    useEffect(()=>{

        return () => {
            const disconnect = sendbirdSelectors.getDisconnect(globalStore);
        }
    }, [])

    useEffect(() => {
        if (channel) {
            // Extract hire ID from the channel name
            const id = extractHireId(channel);
            fetchHireDetails(id);

        }
    }, [channel]);

    const extractHireId = (selectedChannel) => {
        const {name} = selectedChannel;
        const match = name?.match(/\(([^)]+)\)/);
        return match ? match[1] : null;
    };

    const removeFile = (event) => {
        event.stopPropagation();  // Stop the click from propagating up
        URL.revokeObjectURL(uploadedFile.preview);
        setUploadedFile(null);
    };


    const allAdTypes = [...SEGMENTS, ...INTEGRATION_TYPES];
    const selectedAdTypesToShow = hireDetails && hireDetails?.selected_ad_types
        ? allAdTypes.map(adType => {
            if (Object.keys(hireDetails?.selected_ad_types).includes(adType.title)) {
                return {
                    ...adType,
                    ...hireDetails?.selected_ad_types[adType.title],
                };
            }
            return null;
        }).filter(item => item !== null)
        : [];

    const CampaignDetail = ({ icon, text, value }) => {
        return (
            <Box className="campaign-row" style={{ display: 'flex', alignItems: 'center' }}>
                <Box className="campaign-row__icon" style={{ flex: 0.5 ,marginRight:1 ,display:'flex'}}>{icon}</Box>
                <Box className="campaign-row__text" style={{ flex: 4, textAlign: 'left' }}>{text}</Box>
                <Box className="campaign-row__value" style={{ flex: 1, textAlign: 'left' }}>{value}</Box>
            </Box>
        );
    }
    const calculateCompleted = (adTypes) => {
        const completedCount = Object.values(adTypes).filter(ad => ad.status === 'approved').length;
        const totalCount = Object.keys(adTypes).length;
        return `${completedCount} of ${totalCount}`;
    }

    const renderHireDetails = useMemo(() => {
        if (hireDetails) {
            return (
                <div className="hire-details">
                    {/*<AdTypesSmallCard ads={hireDetails?.selected_ad_types} />*/}
                <Divider />
                    <CampaignDetail icon={<ErrorOutline />} text="Campaign Details" value={hireDetails?.campaign_details?.campaign_status}/>
                    <CampaignDetail icon={<MonetizationOnOutlined />} text="Hire price" value={`$${hireDetails?.total_amount?.toLocaleString()}`}/>
                    <CampaignDetail icon={<TaskAltOutlined />} text="Completed" value={calculateCompleted(hireDetails?.selected_ad_types)}/>
                    <Divider />
                    {selectedAdTypesToShow.map((adType, index) => (
                        <Box
                            onClick={()=> {
                                if(user.type === "creator"){
                                    setSelectedAdType(adType.title);
                                    setOpenModal(true);
                                }
                            }}
                            className="ad-type-card"
                            key={index}
                            sx={{cursor: user.type === "creator" ? "pointer": "default" }}
                            display="flex"
                            alignItems="center"
                            flexDirection="column">
                            <Box className="status-label" sx={{ backgroundColor: getStatusColor(adType?.status) }}>{getStatusText(adType?.status)}</Box>
                            <Box display="flex" alignItems="center" width="100%" justifyContent={"space-between"} >
                                <Box>
                                    <Box sx={{marginLeft: 1}}>{adType.title}</Box>
                                </Box>
                                <img style={{width: '75px'}} src={adType.image} alt={adType.title} />
                            </Box>
                            <Box display="flex" alignItems="center" width="100%" justifyContent={"space-between"} >
                                <Box>
                                    <Box sx={{marginLeft: 1}}>{`Due ${dayjs(adType.date).format('DD/MM/YY')}`}</Box>
                                </Box>
                                <Box sx={{marginLeft: 1}}>{`$${adType.amount.toLocaleString()}`}</Box>
                            </Box>
                        </Box>
                    ))}
                </div>
            );
        }
        return null;
    }, [hireDetails]);


    // File upload handler
    const handleFileUpload = async () => {
        setUploading(true);
        const id = extractHireId(channel);

        const formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('channel_url', currentChannelUrl);
        formData.append('user_id', user?._id);
        formData.append('hire_id', id);
        formData.append('ad_type_title', selectedAdType);
        try {
            const res = await apiClient.post(`/uploadFile`,formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {

        } finally {
            setUploading(false);
            setOpenModal(false);
            setUploadedFile(null);
            setUploadStep(false)
            setSelectedAdType(null)
            fetchHireDetails(id)
        }
    };

    const fetchHireDetails = async (id) => {
        try {
            if(id){
                const res = await apiClient.get(`/hire?hireId=${id}&withCampaignData=true`);
                setHireDetails(res.data);
            }
        } catch (e) {
            console.log("Error fetching hire details:", e);
        }
    };

    const [queries] = useState({
        channelListQuery: {
            includeEmpty: true,
            order: 'latest_last_message',
        },
        applicationUserListQuery: {
            limit: 30,
            userIdsFilter: ['katherine', 'cindy'],
        },
    });

    const handleAdReview = async (message, status) => {
        setRequestChangesModal(false)
        // Extract message and channel details
        const message_id = message.messageId;
        const channel_url = message.channelUrl;
        const user_id = message.sender.userId;
        const id = extractHireId(channel);

        // Prepare the payload for the API call
        const payload = {
            message_id: message_id,
            channel_url: channel_url,
            status: status, // Keep the original content
            user_id: user_id,
            requested_change: requestChangesText,
            hire_id: id,
            ad_type_title: message.customType
        };

        try {
            // Make the API call to your Flask endpoint
            const response = await apiClient.post('/updateMessage', payload);
            fetchHireDetails(id);
        } catch (e) {
            console.log("Error updating the message:", e);
        }
    };

    const customHeader = (props) => {
        return <Box className="custom-header-channel">
            <Avatar alt="" src={user?.type === "creator" ? user?.channelDetails?.logo : hireDetails?.brand_logo} />
            <Box>
                {user.companyName || user?.channelDetails?.name}
            </Box>
        </Box>
    }
    const customMessageRenderer = ({ message }) => {
        // Check if the message is a file and if there's a sender\

        if (message.customType?.length > 0 && message.sender) {
            const { type, customType, url } = message;
            // Render based on MIME type
                return ( <Box className="video-message-container" sx={{
                    borderTop: `5px solid ${getStatusColor(message?.data?.status)}`,
                }}>
                    <video controls style={{ width:"100%", maxHeight: "300px" }}>
                        <source src={url} type={type} />
                        Your browser does not support the video tag.
                    </video>
                    <Box className="video-buttons-container">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box className="text-pending-review">
                                {
                                    message?.data?.status === "approved" && <Tooltip title={`Ad was approved on ${dayjs(message.createdAt).format('DD/MM/YYYY h:mm A')}`}>
                                    <Box className="text-pending-review">
                                        <CheckCircleOutline sx={{color: "green"}} />
                                        <span style={{color: 'green'}}>Approved</span>
                                    </Box>
                                    </Tooltip>
                                }
                                {
                                    message?.data?.status === "request_changes" &&
                                    <Tooltip title={`Ad needs more attention`}>
                                        <Box className="text-pending-review">
                                            <ErrorOutline sx={{color: "orange"}} />
                                            <span style={{color: 'orange'}}>Changes Requested</span>
                                        </Box>
                                    </Tooltip>

                                }
                                {
                                    message?.data === "" && <>
                                        <ErrorOutline />
                                        Content Pending Review
                                    </>
                                }
                            </Box>
                            <Tooltip title="Ad was submitted on this date">
                            <Box sx={{color: 'var(--sendbird-light-onlight-02)', fontSize: 14}}>
                                {dayjs(message.createdAt).format('MMMM D, YYYY')}
                            </Box>
                            </Tooltip>
                        </Box>
                        {
                            message?.data?.status === "request_changes" && <Box sx={{width: '80%'}} className="message-deliveredAt">
                                {message?.data?.requested_change}
                            </Box>
                        }
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            {
                                message?.data === "" && user.type === "brand" && (<><Box display="flex" alignContent="flex-start">
                                    <Button className="review-buttons" disableElevation variant="contained" size="small" onClick={() => handleAdReview(message, 'approved')}>Approve</Button>
                                    <Button className="review-buttons" variant="text"  size="small" onClick={() => setRequestChangesModal(message)}>Request a Change</Button>
                                </Box>
                            {
                                (message.sendingStatus === SendingStatus.SUCCEEDED) && <Box className="message-deliveredAt">
                                <Check sx={{
                                width: '16px',
                                height: '16px',
                                color: 'var(--sendbird-light-onlight-02)'
                            }} />
                                <Box sx={{color: 'var(--sendbird-light-onlight-02)', fontSize: 12}}>
                            {dayjs(message.createdAt).format('h:mm A')}</Box>
                                </Box>
                            }</>)
                            }


                        </Box>
                    </Box>
                </Box>)
            }
        // For other messages, return null to use the default rendering
        return null;
    }
    return (
        <div className="customized-app">
            <Box className="section-title">Negotiation Area</Box>
            <Box className="section-description">
                In this section, you can review and negotiate ad videos with creators. Approve content or request changes for a smooth, efficient collaboration.</Box>
           <div className="chat-wrapper">
               <div className="sendbird-app__wrap chat-container">
                   <div className="sendbird-app__channellist-wrap">
                       <SBChannelList
                           queries={queries}
                           renderHeader={customHeader}
                           onChannelSelect={(channel) => {
                               if (channel && channel.url) {
                                   setCurrentChannelUrl(channel.url)
                                   setChannel(channel)
                               }
                           }}
                       />
                   </div>
                   <div className="sendbird-app__conversation-wrap">
                       <SBConversation
                           className="conversation-style"
                           channelUrl={currentChannelUrl}
                           renderMessage={customMessageRenderer}
                       />
                   </div>
               </div>
               <div className="details-area">
                       <Avatar alt="" src={hireDetails?.brand_logo} />
                   <Box>
                       <Box className="brand-name">{hireDetails?.brand_name}</Box>
                   </Box>
                   <Box className="section-title">{hireDetails?.campaign_details?.details?.title}</Box>
                   {renderHireDetails}
               </div>
               <CustomDialog openModal={requestChangesModal} onClose={() => setRequestChangesModal(false)}>
                   <Box className="modal-campaign-container">
                       <Box sx={{mt:1, mb: 2, fontSize: 28}} className="modal-campaign-title">
                           Requested Changes
                       </Box>
                       <CustomTextField
                           name="changeRequest"
                           multiline
                           sx={{width: '80%'}}
                           placeholder="What changes would you like to request?"
                           rows={10}
                           value={requestChangesText}
                           onChange={(e)=>setRequestChangesText(e.target.value)}
                       />
                       <Button
                           disabled={requestChangesText === ""}
                           sx={{mt: 2}}
                           variant="contained"
                           onClick={() => handleAdReview(requestChangesModal, 'request_changes')}>
                           Submit Changes
                       </Button>
                   </Box>
               </CustomDialog>

               <CustomDialog openModal={openModal} onClose={() => setOpenModal(false)} width="700px">
                   {
                       uploadStep ?   <Box className="modal-campaign-container">
                               <img src={UploadIcon} className="modal-campaign-img" />
                               <Box className="modal-campaign-title">
                                   Upload Ad Video file.
                               </Box>
                               <Box className="modal-campaign-description">
                                   Upload your ad video file here. The file must be in mp4 format.
                               </Box>
                               <div {...getRootProps()}>
                                   <input {...getInputProps()} />
                                   <Box
                                       style={{
                                           border: isDragActive ? "2px dashed #000" : "1px dashed #ccc",
                                           padding: "20px",
                                           textAlign: "center",
                                           cursor: "pointer",
                                           marginBottom: "16px",
                                           width: "300px",
                                       }}
                                   >
                                       {uploadedFile ? "File was successfully added!" : isDragActive ? "Drop the file here..." : "Drag and Drop or Click to Upload"}

                                       {uploadedFile && (
                                           <Box className="success-file-upload-meta">
                                               <div>Size: {Math.round(uploadedFile.size / 1024)} KB</div>
                                               <IconButton sx={{color: 'red'}} aria-label="delete" onClick={(event) => removeFile(event)}>
                                                   <DeleteIcon />
                                               </IconButton>
                                           </Box>
                                       )}
                                   </Box>
                               </div>
                               <Button
                                   startIcon={<FadeLoader cssOverride={{
                                       "top": "7px",
                                       "left": "-4px",
                                       "height":"0px",
                                       "width":"0px",
                                   }} margin={-10} height={5} width={3} speedMultiplier={3} color={'#fff'} loading={uploading} />}
                                   disabled={!uploadedFile} variant="contained" onClick={() => handleFileUpload()}>
                                   {uploading ? 'Uploading' : 'Upload'}
                               </Button>
                           </Box>
                           :
                           <Box className="modal-campaign-container">
                               <img src={Advertise} className="modal-campaign-img" />
                               <Box className="modal-campaign-title">
                                   Choose which ad type you would like to upload
                               </Box>
                               <Box mb={3} display="flex" alignItems="center" flexWrap="wrap" gap={2} justifyContent={"center"}>
                                   {selectedAdTypesToShow.map((adType, index) => (
                                       <Box
                                           onClick={() => setSelectedAdType(adType.title)} // Add this line
                                           className="ad-type-card" key={index} display="flex" alignItems="center" justifyContent="center">
                                           <input
                                               type="radio"
                                               name="adType"
                                               value={adType.title}
                                               checked={selectedAdType === adType.title}
                                           />
                                           <Box>
                                               <Box sx={{marginLeft: 1}}>{adType.title}</Box>
                                           </Box>
                                           <img style={{width: '75px'}} src={adType.image} alt={adType.title} />
                                       </Box>
                                   ))}
                               </Box>

                               <Button variant="contained" onClick={()=> setUploadStep(true)}>
                                   Continue
                               </Button>
                           </Box>
                   }

               </CustomDialog>
           </div>

        </div>
    )
};

export default Chat;
