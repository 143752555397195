import {createContext, useContext, useEffect, useState} from "react";
import {UserContext} from "./UserContext";
import {useApiClient} from "../hooks/useApiClient";

const HireContext = createContext();

export const useHire = () => useContext(HireContext);

export const HireProvider = ({ children }) => {
    const apiClient = useApiClient();
    const { user } = useContext(UserContext);

    const [hires, setHires] = useState([]);
    const [fundedHires, setFundedHires] = useState([]);
    const [selectedHires, setSelectedHires] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user) {
            fetchHires();
        }
    }, [user]);

    const fetchHires = async (noCache = false) => {
        setLoading(true);
        try {
            const res = await apiClient.get(`/hires?brandId=${user._id}&no_cache=${noCache}`);
            const pendingHires = res?.data?.filter(hire => hire.status === 'pending');
            const fundedHires = res?.data?.filter(hire => hire.status === 'funded');
            setHires(pendingHires);
            setFundedHires(fundedHires)
        } catch (e) {
            // Handle error
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveHire = async (hireId) => {

        try {
            await apiClient.delete(`/hire/${hireId}`);
            fetchHires(true);
        } catch (e) {
            // Handle error
        }
    };

    const handleCheckboxChange = (hireId) => {
        if (selectedHires.includes(hireId)) {
            setSelectedHires(prevHires => prevHires.filter(id => id !== hireId));
        } else {
            setSelectedHires(prevHires => [...prevHires, hireId]);
        }
    };
    console.log('on hire provider')

    return (
        <HireContext.Provider value={{
            hires,
            setHires,
            fundedHires,
            loading,
            fetchHires,
            handleRemoveHire,
            selectedHires,
            setSelectedHires,
            handleCheckboxChange
        }}>
            {children}
        </HireContext.Provider>
    );
};
