import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    ListItem, ListItemAvatar, ListItemText,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import './styles.css'
import {useHire} from "../../context/HiresContext";
import List from "@mui/material/List";
import CustomInput from "../../components/Input";
import StripeLogo from '../../assets/stripe-logo.svg';
import {useApiClient} from "../../hooks/useApiClient";
import {useSnackbar} from "notistack";
import CustomDialog from "../../components/Dialog";
import MoneyTransfer from "../../assets/money-transfer.svg"
import {useNavigate} from "react-router-dom";

import {loadStripe} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/CheckoutForm";
import { useLocation } from 'react-router-dom';

const stripePromise = loadStripe("pk_test_51No43eKkaAT8Nl4486P1RmGJSUAGKn9eVrbKgbf6rcbs14eEZ9i9Ynrkc4RmRIveJsn6RYlVcTL5aOiClxAvvQKn00AsIUOxxT");

const FundHires = () => {
    const { hires,
        fetchHires,
        selectedHires
    } = useHire();
const apiClient = useApiClient();
const { enqueueSnackbar } = useSnackbar();
const navigate = useNavigate();
const location = useLocation();

const [openModal, setOpenModal] = useState(false);
const [updatingHires, setUpdatingHires] = useState(false);

    const [clientSecret, setClientSecret] = useState("");
    console.log({hires})
    useEffect(() => {
        const createPayment = async () => {
            try {
                const res = await apiClient.post('/create-payment-intent', {
                    selectedHires: selectedHires // Sending only the identifiers of selected hires to the BE
                });
                const { clientSecret } = res.data; // Assuming the clientSecret comes back in the response
                setClientSecret(clientSecret);
            } catch (e) {
                enqueueSnackbar("Something went wrong!", { variant: 'error' });
            }
        };
        if(selectedHires.length > 0){
            createPayment();
        }
    }, []);
    useEffect(() => {
        }, [clientSecret]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const redirectStatus = params.get('redirect_status');
        const paymentIntent = params.get('payment_intent');

        if (redirectStatus === 'succeeded' && paymentIntent) {
            handlePayment(paymentIntent)
        }
    }, [location]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const handlePayment = async (intent) => {
        try {
            setUpdatingHires(true);
            setOpenModal(true);
            const res = await apiClient.put('/hires/bulk-update-status', {
                intentId: intent,
                status: 'funded'
            });
            setUpdatingHires(false);
            fetchHires();
            // enqueueSnackbar("All selected Hires were funded successfully!", { variant: 'success' });

        } catch (e) {
            enqueueSnackbar("Something went wrong!", { variant: 'error' });
        }
    }
// Filter the hires array to get only the selected hires
    const enrichedSelectedHires = hires.filter(hire => selectedHires.includes(hire._id));

    const grandTotalAmount = enrichedSelectedHires.reduce((sum, hire) => sum + hire.total_amount, 0);

    return (
        <Box className="fund-hires-page" display="flex">
            <Box display="flex" flexGrow={1} padding={2} justifyContent="space-between">
                <Box>
                <Box>
                    <Typography sx={{marginBottom: 1}} variant="h4">Fund Hires</Typography>
                    <Typography variant="body1" color="textSecondary" width="70%">
                        Fund your hires to kickstart the content creation process.
                    </Typography>
                </Box>
                {/* List of selected hires */}
                <List>
                    {enrichedSelectedHires.map(hire => (
                        <ListItem key={hire._id}>
                            <ListItemAvatar>
                                <Avatar src={hire.creator_details.logo} alt={hire.creator_details.name} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={hire.creator_details.name}
                                secondary={`Total Amount: $${hire.total_amount}`}
                            />
                        </ListItem>
                    ))}
                </List>
                </Box>
                {/* Grand total amount */}
                <Box>
                <Box
                    display="flex"
                    gap={6}
                    width={300}
                    justifyContent={"space-between"}
                    alignItems="center"
                    margin={2}>
                    <Typography variant="h7">Hires Total Amount</Typography>
                    <Typography variant="h7">${grandTotalAmount.toLocaleString()}</Typography>
                </Box>
                <Box
                    display="flex"
                    width={300}
                    justifyContent={"space-between"}
                    alignItems="center"
                    margin={2}>
                    <Box sx={{display: 'flex'}} alignItems="center" gap={1}>
                        <Typography variant="h7">Service Fees</Typography>
                        <Typography sx={{fontSize: 12}}>(+10%)</Typography>
                    </Box>
                    <Typography variant="h7">${(grandTotalAmount * 0.1).toLocaleString(undefined, {maximumFractionDigits: 0})}</Typography>
                </Box>

                <Box
                    display="flex"
                    width={300}
                    justifyContent={"space-between"}
                    alignItems="center"
                    margin={2}
                    borderTop="1px solid #cfcfcf"
                    paddingTop={3}>
                    <Typography variant="h7">Total Funding Amount</Typography>
                    <Typography fontWeight={700} variant="h7">${(grandTotalAmount * 1.1).toLocaleString(undefined, {maximumFractionDigits: 0})}</Typography>
                </Box>
                </Box>
            </Box>

            {/* Payment section */}
            <Box padding={2}>
                <Card sx={{borderRadius: 0}}>
                    <CardHeader title="Credit Card Payment" />
                    <CardContent>
                        {
                            clientSecret.length > 0 &&
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm />
                                <Box className="powered-by-stripe">
                                    <Box sx={{fontSize: '14px', color: '#868796'}}>
                                    Powered by
                                    </Box>
                                    <img src={StripeLogo} />
                                </Box>
                            </Elements>
                        }

                    </CardContent>
                </Card>
            </Box>
            <CustomDialog openModal={openModal} onClose={() => setOpenModal(false)}>
                <Box className="modal-campaign-container">
                    <img src={MoneyTransfer} className="modal-campaign-img" />
                    <Box className="modal-campaign-title">
                        {
                            updatingHires ?
                                "Funding Hires..."
                                :
                                "Funds were disbursed successfully!"
                        }
                    </Box>
                    <Box className="modal-campaign-description">
                        {
                            updatingHires ?
                                "Please wait while we are funding your hires..."
                                :
                                "You successfully funded all selected Hires, they will be notified to start working on your campaign."
                        }
                    </Box>
                    <Button disabled={updatingHires} variant="contained" onClick={()=> (navigate('/garage'))}>
                        Visit Workroom
                    </Button>
                </Box>
            </CustomDialog>
        </Box>
    );
}

export default FundHires;