import React, {useContext, useState} from 'react';
import { Box, Button } from "@mui/material";
import "./../styles.css"
import CampaignField from "../components/CampaignField";
import BtnUndo, {
    BtnBold,
    BtnItalic,
    createButton,
    Editor,
    EditorProvider,
    BtnNumberedList,
    BtnLink,
    BtnBulletList,
    BtnRedo,
    BtnUnderline,
    Toolbar
} from 'react-simple-wysiwyg';import {useNavigate} from "react-router-dom";
import {useCampaign} from "../../../context/CampaignContext";
import {useProposal} from "../../../context/ProposalContext";
import {UserContext} from "../../../context/UserContext";
import {useApiClient} from "../../../hooks/useApiClient";
import CustomDialog from "../../../components/Dialog";
import CampaignLaunch from "../../../assets/campaign-launch.svg";




const ProposalDetails = () => {

    const { campaignData, setCampaignData } = useCampaign();
    const {proposalData,setProposalData} = useProposal();
    const { user } = useContext(UserContext);
    const apiClient = useApiClient();
    const [openModal, setOpenModal] = useState(false);
    const [proposalCreated, setProposalCreated] = useState(false);
    const [errorModal, setErrorModal] = useState(undefined);

    const navigate = useNavigate();
    function onChange(e) {
        setProposalData(prevData => ({
            ...prevData,
            description: e.target.value
        }));
    }
    const handleNext = async () => {

        window.scrollTo(0, 0);
        const enrichedProposalData = {
            ...proposalData,
            brandId: campaignData.brandId,
            creatorId: user._id,
            creationTime: new Date().toISOString(),
            campaignId: campaignData._id,
            status: 'pending',
        };
        try {
            const res = await apiClient.post(
                `${process.env.REACT_APP_API_URL}/create-proposal`,
                { ...enrichedProposalData }
            );

            setProposalCreated(true)
            setOpenModal(true)
        } catch (error) {
            console.error(error);
            setErrorModal(error?.response?.data?.error)
        }
    }

    const BtnAlignCenter = createButton('Align center', '≑', 'justifyCenter');
    const BtnAlignLeft = createButton('Align left', '≔', 'justifyLeft');
    const BtnAlignRight = createButton('Align right', '≕', 'justifyRight');
    return (
        <Box className="campaign-creator-details">
            <Box className="details-wrapper">
                <Box className="integration-title">
                    Sponsorship Proposal Details
                </Box>
                <Box className="integration-description">
                    Here's your spotlight! Draft a detailed introduction about you and the collaboration you're proposing, so that brands can get to know you better.                </Box>

                    <EditorProvider>
                        <Editor containerProps={{ style: { width: '540px', minHeight: '300px', border: '1px solid #ededed'} }} value={proposalData.description} onChange={onChange}>
                            <Toolbar>
                                <BtnBold />
                                <BtnItalic />
                                <BtnUnderline />
                                <BtnLink />
                                <BtnAlignLeft />
                                <BtnAlignCenter />
                                <BtnAlignRight />
                                <BtnNumberedList/>
                                <BtnBulletList />
                            </Toolbar>
                        </Editor >
                    </EditorProvider>
            </Box>

            <Button className="next-button-campaign" variant="contained" color="primary" onClick={handleNext} >
                Finish
            </Button>
            <CustomDialog openModal={openModal} onClose={() => setOpenModal(false)}>
                <Box className="modal-campaign-container">
                    <img src={CampaignLaunch} className="modal-campaign-img" />
                    <Box className="modal-campaign-title">
                       Proposal created successfully!
                    </Box>
                    <Box className="modal-campaign-description">
                        Your proposal was successfully created and sent to the brand.
                        You will be notified when the brand responds.
                    </Box>
                    <Button variant="contained" onClick={()=> (navigate('/feed?status=applied'))}>
                        Visit All Proposals
                    </Button>
                </Box>
            </CustomDialog>
        </Box>
    );
}

export default ProposalDetails;