import React from 'react';
import {Box} from "@mui/material";



const Sponsorships = () => {


    return (
        <Box>

        </Box>
        );
};

export default Sponsorships;