import React, { useContext } from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import Layout from "../Layout";
import {SendBirdProvider} from "@sendbird/uikit-react";

const PrivateRoute = ({ children }) => {
    const { user, loading, fbUser } = useContext(UserContext);
    const location = useLocation();
    const isRegisterPage = location.pathname === '/register'; // or the correct route for your register page

    if (loading) {
        return null; // or render a loading component
    }

    if (!fbUser) {
        return <Navigate to="/" />;
    } else {

        return <>
            <SendBirdProvider
                appId={"304C768D-A050-41C4-92D4-3A4E64627E9F"}
                userId={user?._id}>
            <Layout showSidebar={!isRegisterPage}>
            {children}
            </Layout>
            </SendBirdProvider>
        </>;
    }
};

export default PrivateRoute;
