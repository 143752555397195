import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {UserProvider} from "./context/UserContext";
import {createTheme} from "./theme";
import {CampaignProvider} from "./context/CampaignContext";
import {ProposalProvider} from "./context/ProposalContext";
import {HireProvider} from "./context/HiresContext";

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <ThemeProvider theme={theme}>
          <UserProvider>
              <CampaignProvider>
               <ProposalProvider>
                   <HireProvider>
                  <App />
                   </HireProvider>
               </ProposalProvider>
              </CampaignProvider>
          </UserProvider>
      </ThemeProvider>
);
reportWebVitals();
