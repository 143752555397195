import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TopBar from "../TopBar";
import {UserContext} from "../../context/UserContext";
import {useContext} from "react";
import {brandRoutes, creatorRoutes, getBrandRoutes} from "./config";
import {useNavigate} from "react-router-dom";
import "./styles.css"
import SponsrateBlack from "./../../assets/sponsrate-logo-black.svg"
import {Badge} from "@mui/material";
import {useHire} from "../../context/HiresContext";
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        background: 'inherit',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function Layout({children, showSidebar, onClose}) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const { user, handleLogout} = useContext(UserContext);
    const navigate = useNavigate();
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const { hires } = useHire();
    const brandRoutesConfig = getBrandRoutes(hires);

    const items = user?.type === "brand" ? brandRoutesConfig : creatorRoutes;

    if(!showSidebar){
        return ( <Main sx={{background:'#FFFFFF', minHeight: '100vh'}} open={open}>
            {children}
        </Main>)
    } else {
        return (
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar sx={{background: '#FFF', boxShadow: 'none'}} position="fixed" open={open} >
                    <TopBar handleDrawerOpen={handleDrawerOpen} isOpened={open}/>
                </AppBar>
                <Drawer
                    className="styled-drawer"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                    <img className="header-icon" src={SponsrateBlack} />
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List className="styled-list">
                        {items.map((item, index) => (
                            <ListItem key={item.title} disablePadding onClick={() => navigate(item.path)}>
                                <ListItemButton>
                                    <ListItemIcon sx={{ minWidth: '40px' }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', }}>
                                        <ListItemText primary={item.title} />
                                        {item?.badge?.count > 0 && (
                                            <Box
                                                sx={{
                                                    marginLeft: 1,
                                                    background:'#2563EB',
                                                    padding: '0px 8px',
                                                    borderRadius: '10px',
                                                    fontWeight: 'bold',
                                                    color: '#FFF',
                                                    fontSize: '12px',
                                                    display: 'flex',
                                                    flexDirection: 'row'}}>  {/* You can adjust the marginLeft as per your design preference */}
                                                {item?.badge?.count} new
                                            </Box>
                                        )}
                                    </Box>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>

                </Drawer>
                <Main sx={{background: '#FFFFFF', minHeight: '100vh'}} open={open}>
                    <DrawerHeader />
                    {children}
                </Main>
            </Box>
        );
    }
}