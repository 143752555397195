import React, { useContext } from 'react';

import {UserContext} from "../../context/UserContext";
import BrandDashboard from "./brand";
import CreatorDashboard from "./creator";

const WithUserType = () => {
    const { user } = useContext(UserContext);

    switch(user?.type) {
        case 'brand':
            return <BrandDashboard />;
        case 'creator':
            return <CreatorDashboard />;
        default:
            return null; // or return an error page/component
    }
}

export default WithUserType;
