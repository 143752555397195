import {createContext, useContext, useEffect, useState} from 'react';

const ProposalContext = createContext();

export const useProposal = () => useContext(ProposalContext);

export const ProposalProvider = ({ children }) => {
    const [proposalData, setProposalData] = useState({
        promoSegments:[],
        integrationTypes:[],
        description: 'Now\'s your moment! write the finer details of your collaboration proposal here.',
    });

    useEffect(() => {
        // This function will be executed when the component unmounts
        return () => {
            setProposalData({
                promoSegments: [],
                integrationTypes: [],
                description: "Now's your moment! write the finer details of your collaboration proposal here.",
            });
        };
    }, []); // The empty array means this useEffect runs only once when the component mounts

    console.log('on proposal provider')

    return (
        <ProposalContext.Provider value={{ proposalData, setProposalData }}>
            {children}
        </ProposalContext.Provider>
    );
};
