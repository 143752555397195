import React, { useContext, useEffect, useState} from 'react';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    Box,
    styled,
    Container, Zoom
} from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Pie } from 'react-chartjs-2';
import {Chart, ArcElement, PieController, Tooltip, Legend } from 'chart.js';
import {BeatLoader, PropagateLoader, PulseLoader} from "react-spinners";
import {Wrapper} from "../Home";
import {UserContext} from "../../context/UserContext";

export const CategoryButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    textTransform: 'none',
    pointerEvents: 'none',
    fontWeight: theme.typography.fontWeightMedium,
}));

Chart.register(ArcElement, PieController, Tooltip, Legend);

const Analytics = () => {
    const [creator, setCreator] = useState(null);
    const { user, loading , refreshingChannelDetails} = useContext(UserContext);

    useEffect(()=>{
        if(user){
            setCreator(user.channelDetails)
        }
    },[user])

    const demographicsData = {
        labels: creator?.demographics?.map((d) => d.label),
        datasets: [
            {
                data: creator?.demographics?.map((d) => d.value),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
                hoverOffset: 4,
            },
        ],
    };

    const chartOptions = {
        maintainAspectRatio: true,
        aspectRatio: 4,
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };

    const DemographicsCard = styled(Card)(({ theme }) => ({
        padding: theme.spacing(2),
        textAlign: 'center',
        borderRadius: theme.spacing(1),
        boxShadow: 'none'
    }));

    const StyledCard = styled(Card)(({ theme }) => ({
        backgroundColor: 'inherit',
        padding: theme.spacing(2),
        boxShadow: 'none !important',
        border: 'none',
    }));

    const StyledRefreshBar = styled(Card)(({ theme }) => ({
        backgroundColor: '#d32f2fb8',
        color: '#fff',
        width: '70%',
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
        boxShadow: 'none',
        border: 'none',
    }));

    const StyledRefreshBarText = styled(Box)(({ theme }) => ({
        marginRight: '8px',
        fontWeight: 500,
    }));

    const StatCard = styled(Card)(({ theme }) => ({
        padding: theme.spacing(2),
        boxShadow: `0 0 15px ${theme.palette.primary.main}50`,
        border: '1px solid transparent',
        borderRadius: theme.spacing(2),
    }));

    const StatLabel = styled(Typography)(({ theme }) => ({
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'left',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
    }));

    const StatValue = styled(Typography)(({ theme }) => ({
        fontSize: '24px',
        fontWeight: 600,
    }));

    const PageContainer = styled(Container)(({ theme }) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    }));

    return (
        <PageContainer maxWidth="lg">
            {
                refreshingChannelDetails
                && <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Zoom direction="down" in={refreshingChannelDetails}>
                    <StyledRefreshBar>
                        <StyledRefreshBarText>Performing real-time analysis with your latest channel data</StyledRefreshBarText>
                        <BeatLoader
                            color={'#fff'}
                            loading={true}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"/>
                    </StyledRefreshBar>
                </Zoom>
            </Box>
            }
            {
                (!creator) ? // If loading or creator is null, show the loader
                    <Wrapper>
                        <PropagateLoader
                        color={'#818181'}
                        loading={true}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    </Wrapper> :
                    <StyledCard>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant="h4">{creator.name}</Typography>
                                    <Typography variant="subtitle1" gutterBottom>{creator.bio}</Typography>
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <YouTubeIcon color="error" />
                                        <Button href={creator.channelUrl} target="_blank" rel="noopener noreferrer" color="error" sx={{ textTransform: 'none', marginLeft: '4px' }}>
                                            YouTube
                                        </Button>
                                        <Grid container sx={{marginLeft: '106px'}}>
                                            {creator?.commonVideoCategories?.map((category, index) => (
                                                <Grid item key={index}>
                                                    <CategoryButton variant="outlined">{`${category[0]} (${category[1] === 50 ? '50+' : category[1]})`}</CategoryButton>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {creator?.stats?.map((stat, index) => (
                                    <Grid item xs={6} sm={3} key={index}>
                                        <StatCard>
                                            <StatLabel>{stat.label}</StatLabel>
                                            <StatValue>
                                                {stat.value.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                {stat.unit && <span>{stat.unit}</span>}
                                            </StatValue>
                                        </StatCard>
                                    </Grid>
                                ))}
                                <Grid item xs={12}>
                                    <DemographicsCard>
                                        <Typography variant="h6" mb={2}>
                                            Audience Demographics
                                        </Typography>
                                        <Pie data={demographicsData} options={chartOptions} />
                                    </DemographicsCard>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </StyledCard>

            }

        </PageContainer>
    );
};

export default Analytics;