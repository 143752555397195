import React, { useState, useEffect } from 'react';
import {Box, Avatar, Typography, Checkbox, FormControlLabel, Chip, Grid} from '@mui/material';
import './styles.css';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from "../Button";
import {useApiClient} from "../../hooks/useApiClient";
import {useHire} from "../../context/HiresContext";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

const getStatusColor = (status) => {
    switch (status) {
        case "pending": return {bg: '#1e88e526', color: '#1e88e5'}; // Blue
        case "completed": return {bg: '#4caf5029', color: '#4caf50'}; // Green
        case "in_progress": return {bg: '#ffc10736', color: '#d29d00'}; // Amber
        default: return "#333"; // Default to a neutral color
    }
}

const STATUS_LABELS = {
    pending: "Pending",
    completed: "Completed",
    in_progress: "In Progress",
}

// Utility function to check if an object is empty
const isEmptyObject = (obj) => Object.keys(obj).length === 0;

const AdTypeCard = ({status, key, value, handleChange, checkedItems}) => {

    return (
        <Box className="ad-type-container">
            <FormControlLabel
                key={key}
                sx={{width: '240px'}}
                control={
                    <Checkbox
                        disabled={status !== 'pending'}
                        name={value.title}
                        checked={checkedItems[value.title] || false}
                        onChange={handleChange}
                    />
                }
                label={<Box sx={{fontWeight: 600}}>{value.title}</Box>}
            />
        <Box display="flex" justifyContent="space-evenly" width="100%">
            <Box className="ad-type-item">
                <Box sx={{color: '#0000007d'}}>Publish date</Box>
                <Box>{new Date(value.date).toLocaleDateString()}</Box>
            </Box>
            <Box className="ad-type-item" sx={{width: '100px', textTransform:'uppercase'}}>
                <Box>{value.feeType}</Box>
            </Box>
            <Box className="ad-type-item" sx={{width: '100px', fontWeight: 600}}>
                <Box>{`$${value.feeAmount}`}</Box>
            </Box>
        </Box>
    </Box>)
}

const ProposalDetails = ({ proposal }) => {
    const { creator_details, status, integration_types, promo_segments } = proposal;

    const [checkedItems, setCheckedItems] = useState({});
    const { hires,
        fundedHires,
        fetchHires,
        selectedHires
    } = useHire();

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const isAlreadyHired = [...fundedHires, ...hires].some(hire => hire.proposal_id === proposal._id);

    useEffect(() => {
        if (isAlreadyHired) {
            const hiredItem = [...fundedHires, ...hires].find(hire => hire.proposal_id === proposal._id);
            const selectedAdTypes = Object.keys(hiredItem.selected_ad_types).reduce((acc, adTypeTitle) => {
                acc[adTypeTitle] = true;
                return acc;
            }, {});
            setCheckedItems(selectedAdTypes);
        }
    }, [hires,fundedHires, proposal._id]);

    const apiClient = useApiClient();

    // Checkbox change handler
    const handleCheckboxChange = (event) => {
        const name = event.target.name;
        const isChecked = event.target.checked;

        setCheckedItems(prevState => ({
            ...prevState,
            [name]: isChecked
        }));
    };

    // "Hire" button click handler
    const handleHireClick = async () => {
        const hire = {
            proposalId: proposal._id,
            selectedAdTypes: checkedItems,
        };
        if(isAlreadyHired){
            navigate('/hires')
        } else {
            // Send POST request to backend
            try {
                const response = await apiClient.post(`/hire`, {
                    ...hire
                });
                fetchHires();
                enqueueSnackbar("New hire has been registered!", { variant: 'success' });
            } catch (error) {
                console.error('There was a problem while creating hire:', error.message);
                enqueueSnackbar("There was a problem while creating hire", { variant: 'error' });

            }
        }
    };

    return (
        <Box padding={2} sx={{border: `4px solid ${getStatusColor(status).bg}`}}>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                <Box display="flex" alignItems="center">
                    <Avatar sx={{width: '70px', height: '70px'}} src={creator_details.channelDetails.logo} alt={creator_details.channelDetails.name} />
                    <Box marginLeft={2} display="flex" flexDirection="column">
                        <Box display="flex" gap={1}>
                            <Typography variant="h6">{creator_details.channelDetails.name}</Typography>
                            <OpenInNewIcon
                                color="primary"
                                sx={{cursor: 'pointer'}}
                                onClick={() => window.open(creator_details.channelDetails.channelUrl, '_blank')}
                            />
                        </Box>
                        <Box display="flex" gap={0.5} marginTop={1} >
                            {creator_details.channelDetails.commonVideoCategories?.map((category, index) => (
                                <Box key={index}>
                                    <Box sx={{padding: 0.5, border: '1px solid #e8e8e8' ,fontSize: 12}}>{`${category[0]}`}</Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
                {/* Status Label */}
                <Box bgcolor={getStatusColor(status).bg}
                     color={getStatusColor(status).color}
                     borderRadius="0px"
                     fontWeight="600"
                     padding="4px 20px"
                     display="flex"
                     textTransform="capitalize"
                     alignItems="center">
                    {STATUS_LABELS[status]}
                </Box>
            </Box>

            {/* Stats */}
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap" marginBottom={2}
                maxWidth="100%"
                padding={3}>
                {creator_details.channelDetails.stats.map((stat, index) => (
                    <Box
                        className="stat-box"
                        key={index}
                    >
                        <Box sx={{maxWidth: '100px'}}>
                            {stat.label}
                        </Box> 
                        <Box sx={{fontWeight: 600}}>
                        {stat.value.toLocaleString(undefined, {maximumFractionDigits: 2})}{stat.unit || ''}
                        </Box>
                    </Box>
                ))}
            </Box>

            {/* Integration Types */}
            <Typography sx={{fontWeight: 600}}>{`Select the ad types for your collaboration with ${creator_details.channelDetails.name}:`}</Typography>
                <Box className="ad-types-wrapper">
                    {Object.entries(integration_types).map(([key, value]) => (
                        <AdTypeCard status={status} checkedItems={checkedItems} handleChange={handleCheckboxChange} key={key} value={value} />
                    ))}
                    {Object.entries(promo_segments).map(([key, value]) => (
                        <AdTypeCard status={status} checkedItems={checkedItems} handleChange={handleCheckboxChange}  key={key} value={value} />
                    ))}
                </Box>
            {
                status === 'pending' &&
                <>
                    <Button
                        sx={{marginTop: 2}}
                        variant="contained"
                        color="primary"
                        disabled={isEmptyObject(checkedItems)}
                        onClick={handleHireClick}>
                        {isAlreadyHired ? "Fund Hire" : "Hire"}
                    </Button>
                    <Button
                        sx={{marginTop: 2}}
                        variant="elevated"
                        color="primary">
                        Not a match</Button>
                </>
            }

        </Box>
    );
}

export default ProposalDetails;
