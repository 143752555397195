import React from "react";
import {useCampaign} from "../../../../context/CampaignContext";
import {useNavigate} from "react-router-dom";
import {Box, Button} from "@mui/material";
import CampaignField from "../components/CampaignField";
import ValidationBehaviorView from "../../../../components/DatePicker";
import SelectCard from "../../../../components/SelectCard";
import AdvancedCardSelect from "../../../../components/AdvancedSelectCard";
import {INTEGRATION_TYPES, SEGMENTS} from "../../index";
import Audience from "../../../../components/Audience";


const CardSelect = () => {
    const { campaignData, setCampaignData } = useCampaign();

    const handleCardSelect = (title) => () => {

        setCampaignData(prevData => {
            const isSelected = prevData.adSet?.integrationTypes?.[title];
            return {
                ...prevData,
                adSet: {
                    ...prevData.adSet,
                    integrationTypes: {
                        ...prevData.adSet?.integrationTypes,
                        [title]: !isSelected
                    }
                }
            };
        });
    };


    return (
        <Box>
            {INTEGRATION_TYPES.map((option) => (
                <Box className="campaign-creator-card-select" key={option.title}>
                    <SelectCard
                        selected={!!campaignData.adSet.integrationTypes[option.title]}
                        onChange={handleCardSelect(option.title)}
                        {...option}
                    />
                </Box>
            ))}
        </Box>

    );
}

const PromoSegments = () => {
    const { campaignData, setCampaignData } = useCampaign();

    const handleCardSelect = (title) => () => {
        setCampaignData(prevData => ({
            ...prevData,
            adSet: {
                ...prevData.adSet,
                promoSegments: {
                    ...prevData.adSet.promoSegments,
                    [title]: {
                        ...prevData.adSet.promoSegments?.[title],
                        selected: !(prevData.adSet.promoSegments?.[title]?.selected)
                    }
                }
            }
        }));
    };

    const handleDurationChange = (title) => (event) => {
        setCampaignData(prevData => ({
            ...prevData,
            adSet: {
                ...prevData.adSet,
                promoSegments: {
                    ...prevData.adSet.promoSegments,
                    [title]: {
                        ...prevData.adSet.promoSegments?.[title],
                        duration: event.target.value
                    }
                }
            }
        }));
    };


    return (
        <Box>
            {SEGMENTS.map((option, index) => (
                <Box className="campaign-creator-card-select" key={index}>
                    <AdvancedCardSelect
                        selected={!!campaignData.adSet.promoSegments[option.title]?.selected}
                        onChange={handleCardSelect(option.title)}
                        duration={campaignData.adSet.promoSegments[option.title]?.duration || '15'}
                        onDurationChange={handleDurationChange(option.title)}
                        {...option}
                    />
                </Box>

            ))}
        </Box>
    );
}




const Adset = () => {

    const { campaignData, setCampaignData } = useCampaign();
    const navigate = useNavigate();

    const handleNext = () => {
        window.scrollTo(0, 0);
        navigate('/campaign/create/preview');
    }


    const handleVideoPublishingDeadlineChange = (dateValue) => {
        setCampaignData(prevData => ({
            ...prevData,
            adSet: {
                ...prevData.adSet,
                videoPublishingDeadline: dateValue
            }
        }));
    }


    return (
        <Box className="campaign-creator-adset">
            <CampaignField
                title="Video Publishing Deadline"
                description="Specify the latest date by which sponsored videos should be live. Extending this deadline may attract more proposals, as creators have added flexibility."
            >
              <ValidationBehaviorView
                  onChange={handleVideoPublishingDeadlineChange}
                  value={campaignData?.adSet?.videoPublishingDeadline || null}
              />
            </CampaignField>

            <CampaignField
                title="Who should see your ads?"
                description="Optimize your reach. Define location, demographics, and interests. We'll match you with content creators whose audience aligns best with your preferences."
            >
                <Audience handleChange={()=>{}}/>
            </CampaignField>

            <CampaignField
                title="Choose Integration Style"
                description="Select how your advertisement will be seamlessly woven into the creator's content, ensuring a natural and engaging experience for viewers."
            >
                <CardSelect />

            </CampaignField>
            <CampaignField
                title="Select Promotion Segments"
                description="Choose when and how your brand is highlighted within the video. Select from opening features, mid-video engagements, closing notes, and more to craft a seamless and impactful viewer experience."
            >
                <PromoSegments />
            </CampaignField>
            <Button className="next-button-campaign" variant="contained" color="primary" onClick={handleNext} >
                Next
            </Button>
        </Box>
    );
}
export default Adset;