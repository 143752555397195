import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Avatar,
    Divider,
    SvgIcon,
    Box,
} from '@mui/material';
import '../../styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CalendarIcon from "./../../../../assets/icon_calendar_date.svg"
import {ReactComponent as BudgetIcon} from "./../../../../assets/icon_money_bag.svg"
import {ReactComponent as DueDateIcon} from "./../../../../assets/icon_collaborate.svg"
import dayjs from 'dayjs'
import {useNavigate} from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import {INTEGRATION_TYPES, SEGMENTS} from "../../../Campaigns";
import {getStatusColor, getStatusText} from "../../../../components/Chat";


// Slider settings
const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerPadding: '10px',  // or whatever gap size you'd like
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '1px',
                slidesToShow: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
            }
        }
    ]
};

const ProposalItem = ({proposal}) => {
    const allAdTypes = [...SEGMENTS, ...INTEGRATION_TYPES];
    const selectedAdTypesToShow = proposal && (proposal?.integration_types || proposal?.promo_segments)
        ? allAdTypes.map(adType => {
            if (Object.keys(proposal?.integration_types).includes(adType.title)) {
                return {
                    ...adType,
                    ...proposal?.integration_types[adType.title],
                };
            } else if (Object.keys(proposal?.promo_segments).includes(adType.title)) {
                return {
                    ...adType,
                    ...proposal?.promo_segments[adType.title],
                };
            }
            return null;
        }).filter(item => item !== null)
        : [];

    return <Box m={"0px 60px"} mb={5}>
        <Slider {...settings}>
            {selectedAdTypesToShow.map((adType, index) => (
                <Box
                    className="ad-type-card"
                    key={index}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Box className="status-label" sx={{ backgroundColor: getStatusColor(adType?.status) }}>{getStatusText(adType?.status)}</Box>
                    <Box display="flex" alignItems="center" width="100%" justifyContent={"space-between"} >
                        <Box>
                            <Box sx={{ marginLeft: 1 }}>{adType.title}</Box>
                        </Box>
                        <img style={{ width: '75px' }} src={adType.image} alt={adType.title} />
                    </Box>
                    <Box display="flex" alignItems="center" width="100%" justifyContent={"space-between"}  padding="10px 0px">
                        <Box>
                            <Box sx={{ marginLeft: 1 }}>{`Due ${dayjs(adType.date).format('DD/MM/YY')}`}</Box>
                        </Box>
                        <Box sx={{ marginLeft: 1 }}>{`$${Number(adType.feeAmount).toLocaleString()}`}</Box>
                    </Box>
                </Box>
            ))}
        </Slider>
    </Box>
}

const FeedItem = ({ item }) => {
    const { details, adSet } = item;
    const navigate = useNavigate();
    const handleApply = (it) => {
        window.scrollTo(0, 0);
        navigate(`/campaign/${it._id}/proposal/create/adset`)
    }
    const [showProposalDetails, setShowProposalDetails] = React.useState(false);

    //function to calculate the total amount of fees from all ad types of a proposal
    // each proposal has two arrays of ad types, integration_types and , promo_segments
    const calculateTotalFees = (proposal) => {

        const allAdTypes = [...SEGMENTS, ...INTEGRATION_TYPES];
        const selectedAdTypesToShow = proposal && (proposal?.integration_types || proposal?.promo_segments)
            ? allAdTypes.map(adType => {
                if (Object.keys(proposal?.integration_types).includes(adType.title)) {
                    return {
                        ...adType,
                        ...proposal?.integration_types[adType.title],
                    };
                } else if (Object.keys(proposal?.promo_segments).includes(adType.title)) {
                    return {
                        ...adType,
                        ...proposal?.promo_segments[adType.title],
                    };
                }
                return null;
            }).filter(item => item !== null)
            : [];
        return selectedAdTypesToShow.reduce((acc, adType) => acc + Number(adType.feeAmount), 0);
    }

    return (
        <Card sx={{ display: 'flex',borderRadius: 0, flexDirection: 'column', marginBottom: '32px', alignItems: 'flex-start' }}>

            <div className="card-header">
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <Avatar
                        src={item.brandLogo}
                        sx={{ height: 80, width: 80 }}
                    />
                    <div style={{ marginLeft: '16px' }}>
                        <Typography variant="h6">{details.title}</Typography>
                        <Typography variant="subtitle2">{item.brandName}</Typography>
                        <Typography variant="caption">Created: {dayjs(new Date(item.creation_timestamp)).format('DD/MM/YYYY hh:mm')}</Typography>
                    </div>
                </div>
                <div className="left-top-section">
                    <div className="icon-wrapper">
                        <SvgIcon className="feed-icon" component={DueDateIcon} inheritViewBox/>
                        <div className="icon-text" variant="caption">
                            <div className="icon-text-title">Match</div>
                            <div className="icon-text-value" style={{color: `${item.matching_percentage > 0.65 ? "green" : "red"}`}}>{Math.floor(item.matching_percentage)}%</div>
                        </div>
                    </div>
                    <div className="icon-wrapper">
                        <SvgIcon className="feed-icon" component={BudgetIcon} inheritViewBox/>
                            <div className="icon-text" variant="caption">
                            <div className="icon-text-title">Budget</div>
                            <div className="icon-text-value">{details.budget}</div>
                        </div>
                    </div>
                    <div className="icon-wrapper">
                        <img className="feed-icon" src={CalendarIcon}/>
                        <div className="icon-text" variant="caption">
                            <div className="icon-text-title">Deadline</div>
                            <div className="icon-text-value">{new Date(adSet.videoPublishingDeadline).toLocaleDateString()}</div>
                        </div>
                    </div>

                </div>
            </div>

            <Divider />

            <CardContent className="card-content">
                <div className="card-content-description">
                    <div>{details.description}</div>
                    <div><a href={details.brandURL} target="_blank" rel="noopener noreferrer">{details.brandURL}</a></div>
                    {
                        item?.proposal_details && (
                            <div className="proposal-details">
                                <Button
                                    onClick={()=> setShowProposalDetails(!showProposalDetails)}
                                    variant="outlined"
                                    className="proposal-details-button">Proposal Details</Button>
                            </div>
                        )
                    }
                </div>

                <Button disabled={item?.proposal_details} onClick={()=>{handleApply(item)}} className="apply-button" size="large" variant={"outlined"}>
                    { item?.proposal_details ? "Applied" : "Apply Now" }
                </Button>
            </CardContent>
            <Collapse sx={{ mt: 3, width: '100%' }} in={showProposalDetails}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4}}>
                    <Divider/>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                        <Box sx={{display: 'flex', gap: 2}}>
                            <Box sx={{fontWeight: 400}}>
                                Total projected gains:
                            </Box>
                            <Box sx={{fontWeight: 600}}>
                                {`$${calculateTotalFees(item?.proposal_details).toLocaleString()}`}
                            </Box>
                        </Box>
                        <Box sx={{display: 'flex', gap: 2}}>
                            <Box sx={{fontWeight: 400}}>
                                Proposal Status:
                            </Box>
                            <Box sx={{fontWeight: 600, textTransform: 'capitalize'}}>
                                {item?.proposal_details?.status}
                            </Box>
                        </Box>
                    </Box>

                    <Divider/>
                    <Box sx={{ml: 8}}>
                        <Box sx={{fontWeight: 500}}>
                            Proposed Ad Types
                        </Box>
                    </Box>
                    <ProposalItem proposal={item?.proposal_details} />
                </Box>
            </Collapse>

        </Card>
    );
};

export default FeedItem;
