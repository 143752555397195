import { Box, Checkbox, Card, CardContent, Typography, CardActionArea } from '@mui/material';

const CardSelect = ({ title, description, image,imageSelected, selected, onChange }) => {

    const handleCheckboxClick = (event) => {
        event.stopPropagation(); // Stop the event from bubbling up to the parent
        onChange(event);
    };

    return (
        <Box
            variant="outlined"
            sx={{ border: '1px solid', borderColor: selected ? 'blue' : 'grey.300' , boxShadow: selected ? '0 0 5px rgba(74, 144, 226, 0.5)' : 'none'}}
        >
            <CardActionArea onClick={onChange}>
                <Box display="flex">
                    <Box flex={3}>
                        <CardContent>
                            <Box display="flex" alignItems="center">
                                <Checkbox checked={selected} inputProps={{ onClick: handleCheckboxClick }} />
                                <Typography variant="h6">{title}</Typography>
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                                {description}
                            </Typography>
                        </CardContent>
                    </Box>
                    <Box flex={1} display="flex" justifyContent="center" alignItems="center">
                        <img src={selected ? imageSelected : image} alt={title} style={{ maxWidth: '100%' }} />
                    </Box>
                </Box>
            </CardActionArea>
        </Box>
    );
};

export default CardSelect;
