import { Box, Typography } from "@mui/material";

const CampaignField = ({ title, description, children }) => {
    return (
        <Box marginBottom={3}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2" color="textSecondary" marginBottom={1} marginTop={1}>
                {description}
            </Typography>
            {children}
        </Box>
    );
};

export default CampaignField;
