import {Outlet, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import "./styles.css"
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useCampaign} from "../../context/CampaignContext";
import {useApiClient} from "../../hooks/useApiClient";
import {useProposal} from "../../context/ProposalContext";

const steps = [
    'Ad sets',
    'Details',
];

const ProposalSteps = ({ activeStep }) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}


const ProposalCreator = () => {
    // const { proposalData } = useProposal();
    const {campaignData, setCampaignData} = useCampaign();
    const apiClient = useApiClient();
    const location = useLocation();
    const campaignId = location.pathname.split('/')[2];
    useEffect(()    => {
        //fetch campaign details from backend
        //wrap this with async function and call it in useEffect
        const fetchCampaign = async () => {
            const campaign = await apiClient.get(`/campaign/${campaignId}`);
            setCampaignData(campaign.data);
        }
        fetchCampaign();
    },[])

    // Determine the active step based on the path name
    const getActiveStep = () => {
        const path = location.pathname;
        if (path.includes('adset')) {
            return 0;
        }
        if (path.includes('details')) {
            return 1;
        }
        return 0;
    };

    // Store the active step
    const activeStep = getActiveStep();


    return (
        <Box sx={{margin: '48px auto', maxWidth: '1040px'}}>
            {/* Render the current step component */}
            <Box className="proposal-creator-header">
               <Box className="proposal-creator-title">
                   Submit Proposal
               </Box>
                <ProposalSteps activeStep={activeStep} />
            </Box>
            <Outlet />
        </Box>
    );
}

export default ProposalCreator;
