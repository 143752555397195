import React, {createContext, useState, useEffect, useRef} from 'react';
import {getAuth, signOut} from 'firebase/auth';
import axios from 'axios';
import {PropagateLoader, PulseLoader} from "react-spinners";
import {FullWidthWrapper, Wrapper} from "../pages/Home";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [fbUser, setFbUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshingChannelDetails, setRefreshingChannelDetails] = useState(false);
    const cancelSourceRef = useRef(null);
    console.log('on user provider')

    const auth = getAuth();

    const cancelOngoingRequests = () => {
        if (cancelSourceRef.current) {
            cancelSourceRef.current.cancel();
            setError(false);
        }
    };

    // Handle logout
    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('auth_token');
                localStorage.removeItem('tokenExpiresAt');
            })
            .catch((error) => {
                console.error('Error signing out: ', error);
            });

        cancelOngoingRequests();
        setError(false);
    };

    const getAccessToken = () => localStorage.getItem('accessToken');
    const getAuthToken = () => localStorage.getItem('auth_token');

    let currentAuthUserEmail = null; // outside of useEffect, inside UserProvider

    const checkUser = async (email, currUserEmail) => {

        cancelSourceRef.current = axios.CancelToken.source();

        if (currUserEmail !== email) {
            currUserEmail = email;
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/user-check/${email}`, { cancelToken: cancelSourceRef.current.token });
                if (res?.data?.user) {
                    const {user} = res.data;
                    const auth_token = res.data.auth_token;
                    // Store the auth_token in localStorage
                    localStorage.setItem('auth_token', auth_token);
                    setUser(user);
                    setLoading(false);
                    if(user.type === "creator"){
                        try {
                            setRefreshingChannelDetails(true)
                            await axios.get(`${process.env.REACT_APP_API_URL}/getChannelDetails?user_id=${user._id}`, {
                                headers: {
                                    'Authorization': `Bearer ${getAccessToken()}`, // Ensure this token is valid
                                    'auth-token': `${getAuthToken()}`, // Ensure this token is valid

                                },
                                cancelToken: cancelSourceRef.current.token
                            });

                            const updatedUserRes = await axios.get(`${process.env.REACT_APP_API_URL}/user-check/${email}`, { cancelToken: cancelSourceRef.current.token });
                            const updatedUser = updatedUserRes?.data?.user;
                            setRefreshingChannelDetails(false)
                            setUser(updatedUser);
                        } catch (error) {
                            console.error('Error:', error);
                            setLoading(false);
                            setRefreshingChannelDetails(false)
                            if(error.message !== "canceled")
                              setError(error)
                        }
                    }
                } else {
                    setUser(null);
                }
                setLoading(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
                if(!error.message === "canceled")
                    setError(err)
            }
        }
    };


    useEffect(() => {
        let currentUserEmail = null;

        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
            const newAuthUserEmail = firebaseUser ? firebaseUser.email : null;
            if (currentAuthUserEmail === null || (currentAuthUserEmail !== newAuthUserEmail)) {
                currentAuthUserEmail = newAuthUserEmail;
                if (firebaseUser) {
                    checkUser(firebaseUser.email, currentUserEmail);
                    setFbUser(firebaseUser)
                } else {
                    setUser(null);
                    setFbUser(null);
                    setLoading(false);
                }
            }
        });

        return () => unsubscribe();
    }, [auth]);

    return (
        <UserContext.Provider value={{ user, loading, handleLogout, setUser, setFbUser, fbUser, refreshingChannelDetails, checkUser , error}}>
            {loading ? (
                <FullWidthWrapper>
                    <PropagateLoader
                    color={'#818181'}
                    loading={loading}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader" />
                </FullWidthWrapper>
            ) : (
                children
            )}
        </UserContext.Provider>
    );
};
