import {Box, Button} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import EmptyState from "../../assets/empty-state.svg";
import './styles.css'
import CustomButton from "../../components/Button";
import { useNavigate } from 'react-router-dom';
import Intro from "../../assets/into.svg";
import IntroSelected from "../../assets/into-selected.svg";
import EngagementImage from "../../assets/engagement.svg";
import EngagementImageSelected from "../../assets/engagement-selected.svg";
import ClosingImage from "../../assets/closing.svg";
import ClosingImageSelected from "../../assets/closing-selected.svg";
import ProductImage from "../../assets/product.svg";
import ProductImageSelected from "../../assets/product-selected.svg";
import Marketing1 from "../../assets/marketing1.svg";
import Marketing1Selected from "../../assets/marketing1-checked.svg";
import Marketing2 from "../../assets/marketing2.svg";
import Marketing2Selected from "../../assets/marketing2-checked.svg";
import Marketing3 from "../../assets/marketing3.svg";
import Marketing3Selected from "../../assets/marketing3-checked.svg";
import Marketing4 from "../../assets/marketing4.svg";
import Marketing4Selected from "../../assets/marketing4-checked.svg";
import Marketing5 from "../../assets/marketing5.svg";
import Marketing5Selected from "../../assets/marketing5-checked.svg";
import Marketing6 from "../../assets/marketing6.svg";
import Marketing6Selected from "../../assets/marketing6-checked.svg";
import {useApiClient} from "../../hooks/useApiClient";
import {UserContext} from "../../context/UserContext";
import CampaignCard from "../../components/CampaignCard";
import {PropagateLoader} from "react-spinners";
import {useHire} from "../../context/HiresContext";


export const SEGMENTS = [
    {
        title: 'Opening Feature',
        description: 'A brief promotion for your brand at the very beginning of a video, as viewers are tuning in.',
        image: Intro,
        imageSelected: IntroSelected,
    },
    {
        title: 'Engagement Slot',
        description: 'A promotional mention during the video when viewers are deeply engaged.',
        image: EngagementImage,
        imageSelected: EngagementImageSelected,
    },
    {
        title: 'Closing Note',
        description: 'A promotion for your brand at the end of a video, leaving a lasting impression on viewers.',
        image: ClosingImage,
        imageSelected: ClosingImageSelected,
    },
    {
        title: 'Visual Highlight',
        description: 'A static or animated visual (image or text) displayed during the video, usually positioned at a corner of the screen.',
        image: ProductImage,
        imageSelected: ProductImageSelected,
    },
]


export const INTEGRATION_TYPES = [
    {
        title: 'Spotlight Feature',
        description: 'The product or service is prominently featured within a segment of the creator\'s video.',
        image: Marketing1,
        imageSelected: Marketing1Selected,
    },
    {
        title: 'Exclusive Review',
        description: 'A full video devoted to exploring and reviewing a product or service.',
        image: Marketing2,
        imageSelected: Marketing2Selected,
    },
    {
        title: 'Collaborative Episode',
        description: 'A joint video where a representative from the brand collaborates with the creator.',
        image: Marketing3,
        imageSelected: Marketing3Selected,
    },
    {
        title: 'Viewer Contest & Giveaways',
        description: 'The creator organizes a contest or giveaway featuring a product/service to excite and engage viewers.',
        image: Marketing4,
        imageSelected: Marketing4Selected,
    },
    {
        title: 'Unique Proposal',
        description: 'Inviting creators to pitch their own original ideas for integrating the brand into their content.',
        image: Marketing5,
        imageSelected: Marketing5Selected,
    },
    {
        title: 'Tutorial Segment',
        description: 'Creators produce a how-to guide or tutorial focused on a product or service.',
        image: Marketing6,
        imageSelected: Marketing6Selected,
    },
];

const Campaigns = (props) => {
    const navigate = useNavigate();
    const { fetchHires } = useHire();
    const [campaigns, setCampaigns] = useState([]);
    const apiClient = useApiClient();
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        fetchCampaigns()
        fetchHires()
    },[])

    const fetchCampaigns = async (noCache = false) => {
        setLoading(true);
        try {
            const res = await apiClient.get(`/fetch-campaigns?user_id=${user._id}&no_cache=${noCache}`);
            setCampaigns(res?.data)
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    const createCampaign = () => {
        navigate('/campaign/create/details');
    }

    return (<Box className="campaigns-page">
        {
            loading ? <Box className="empty-state">
                <Box className="empty-text">
                    <PropagateLoader
                        color={'#818181'}
                        loading={loading}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </Box>
            </Box> : (campaigns.length > 0 ? <Box>
                <Box className="page-title">
                    All Campaigns
                    <CustomButton onClick={createCampaign} variant="contained">
                        Create Campaign
                    </CustomButton>
                </Box>
                <Box className="campaigns-container">
                    {campaigns.map(campaign => (
                        <CampaignCard campaign={campaign} refresh={fetchCampaigns}/>
                    ))}
                </Box>
            </Box> :
                <Box className="empty-state">
                    <img className="empty-img" src={EmptyState} />
                    <Box className="empty-text">
                        Currently you have no campaigns
                    </Box>
                    <CustomButton onClick={createCampaign} variant="contained">
                        Create Campaign
                    </CustomButton>
                </Box>)
        }
    </Box>);
}

export default Campaigns;