import React, {useState} from 'react';
import { Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';
import './styles.css';
import TagSponsorshipModal from "../Modal";
import {PulseLoader} from "react-spinners";

function VideoDetails({ video, refresh, fetchSponsorshipDetails}) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sponsorshipDetails, setSponsorshipDetails] = useState(null);
    const handleTagSponsorshipClick = async () => {
        setIsModalOpen(true);
        const res = await fetchSponsorshipDetails();
        setSponsorshipDetails(res);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    if (!video) return <div></div>;
    const {
        snippet: {
            title,
            thumbnails: { maxres },
            description,
            channelId,
            channelTitle,
            publishedAt,
        },
    } = video;

    const formattedDate = new Date(publishedAt).toLocaleDateString();

    return (
            <Box className="card-container">
                <CardMedia
                    className="card-media"
                    component="img"
                    image={maxres?.url}
                />
                <CardContent className="video-card-content">
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        By {channelTitle} ({channelId})
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Published on {formattedDate}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className="description">
                        {description}
                    </Typography>
                </CardContent>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ marginRight: '20px' }}>
                    <Button
                        className="card-button"
                        variant="outlined"
                        onClick={handleTagSponsorshipClick}
                        >
                        Get Analytics
                    </Button>
                    <TagSponsorshipModal sponsorshipDetails={sponsorshipDetails} refresh={refresh} open={isModalOpen} onClose={handleModalClose} videoId={video.id} creatorId="YOUR_CREATOR_ID" />

                </Box>
            </Box>
    );
}

export default VideoDetails;
