import ChartBarIcon from '@heroicons/react/24/solid/ChartBarIcon';
import ShoppingBagIcon from '@heroicons/react/24/solid/ShoppingBagIcon';
import UserIcon from '@heroicons/react/24/solid/UserIcon';
import Feed from '@heroicons/react/24/outline/QueueListIcon'
import Dashboard from "../../assets/dashboard.svg"
import Analytics from "../../assets/analytics.svg"
import Garage from "../../assets/garage.svg"
import {ReactComponent as Campaign} from "../../assets/megaphone.svg"
import HireIcon from "../../assets/hire.svg"
import Profile from "../../assets/profile.svg"
import Sponsorships from "../../assets/sponsorships.svg"
import { SvgIcon } from '@mui/material';
import Campaigns from "../../pages/Campaigns";

export const getBrandRoutes = (hires) => [
    {
        title: 'Campaigns',
        path: '/campaigns',
        icon: (
            <Campaign className="drawer-icon" />
        )
    },
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: (
            <img src={Dashboard} />
        )
    },
    {
        title: 'Garage',
        path: '/garage',
        icon: (
            <img style={{
                width: '24px',
                height: '24px'
            }} src={Garage} />
        )
    },
    {
        title: 'Pending Hires',
        path: '/hires',
        icon: (
            <img style={{
                width: '24px',
                height: '24px'
            }} src={HireIcon} />
        ),
        badge: {
            count: hires ? hires.length : 0,
            color: 'blue'
        }
    }
];


export const creatorRoutes = [
    {
        title: 'Feed',
        path: '/feed',
        icon: (
            <SvgIcon fontSize="medium">
                <Feed />
            </SvgIcon>
        )
    },
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: (
            <img src={Dashboard} />
        )
    },
    {
        title: 'Sponsorships',
        path: '/sponsorships',
        icon: (
            <img
             style={{
                width: '24px',
                height: '24px'}}
             src={Sponsorships}
            />
        )
    },
    {
        title: 'Garage',
        path: '/garage',
        icon: (
            <img style={{
                width: '24px',
                height: '24px'
            }} src={Garage} />
        )
    },
];
