import React, {useContext, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import {Avatar, Box, Button, Checkbox, TextField, Slide, LinearProgress} from "@mui/material";
import "./styles.css";
import Brand from "../../assets/brand.svg";
import BrandSelected from "../../assets/brand-selected.svg";
import CreatorSelected from "../../assets/creator-selected.svg";
import Creator from "../../assets/creator.svg";
import axios from "axios";
import WomenProfile from "./../../assets/women-profile.png";
import ArrowBack from "@mui/icons-material/ArrowBack";
import CustomTextField from "../../components/CustomTextField";
import CustomDialog from "../../components/Dialog";
import CraftingProfile from "../../assets/crafting-profile.gif";
import {useSnackbar} from "notistack";

const formatPhoneNumber = (value) => {
    // Add your formatting code here
    // This is a simple example, please adjust according to your needs
    return value.replace(/\D/g, "").replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3");
};

const Register = () => {
    const navigate = useNavigate();
    const { fbUser, checkUser } = useContext(UserContext);
    const [selected, setSelected] = useState("");
    const [userCreation, setUserCreation] = useState(null);
    const [step, setStep] = useState(0);
    const [showCreationModal, setShowCreationModal] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [error, setError] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const [brandInfo, setBrandInfo] = useState({
        companyName: "",
        website: "",
        contactPerson: "",
        contactEmail: "",
        contactPhoneNumber: "",
        companyAddress: "",
        industryCategory: "",
        brandBio: "",
        socialMediaHandles: "",
        logo: "",
    });

    const confettiTimeoutRef = useRef(null); // Store the timeout

    useEffect(() => {
        if (userCreation?.userId) {
            navigate(userCreation.type === "brand" ? "/brand-profile" : "/creator-profile");
        }
    }, [userCreation]);

    const handleBack = () => {
        if (step > 0) {
            setStep((prevStep) => prevStep - 1);
        }
    };

    const handleNext = (e) => {
        if(selected === 'creator'){
            setShowCreationModal(true);
             setTimeout(() => {
                setShowConfetti(true);
            }, 3500);
        } else {
            if (step < 4) {
                setStep((prevStep) => prevStep + 1);
            } else if (step === 4) {
                handleSubmit(e);
            }

        }

    };

    const handleInputChange = (e) => {
        setBrandInfo({ ...brandInfo, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let newUser = {};
        try {
            if(selected === 'creator'){
                newUser = { type: selected, email: fbUser.email };
            } else {
                newUser = { type: selected, email: fbUser.email, ...brandInfo };
            }
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/create-user`,
                { ...newUser }
            );
            setUserCreation({ userId: res?.data?.userId, ...newUser });
            await checkUser(fbUser.email, null);
        } catch (error) {
            console.error(error);
            setError(error.message);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
        }
    };

    const BrandFormStepHeader = ({ title, description }) => {
        return (
            <Box className="brand-form-header">
                <Box className="registerTitle">{title}</Box>
                <Box className="registerSubTitle">{description}</Box>
            </Box>
        );
    };

    return (
        <Box className="register">
            <Box className="register-topBar">
                <Box className="logo-title">Sphere X</Box>
                <Avatar className="avatar-image" src={WomenProfile} />
            </Box>
            {step === 0 && (
                <Slide direction="left" in={step === 0} mountOnEnter unmountOnExit>
                    <Box className="step-wrapper">
                        <BrandFormStepHeader
                            title="Let's get started!"
                            description="Are you joining us as a Brand or a Creator?"
                        />
                        <Box className="register-buttons">
                            <Box
                                className={`buttonWrapper ${
                                    selected === "brand" ? "buttonWrapper-selected" : ""
                                }`}
                                onClick={() => setSelected("brand")}
                            >
                                <img
                                    className="brand-image"
                                    src={selected === "brand" ? BrandSelected : Brand}
                                />
                                <label className="brand-text">
                                    Brand
                                    <Checkbox
                                        sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                                        checked={selected === "brand"}
                                        onChange={() => setSelected("brand")}
                                    />
                                </label>
                            </Box>
                            <Box
                                className={`buttonWrapper ${
                                    selected === "creator" ? "buttonWrapper-selected" : ""
                                }`}
                                onClick={() => setSelected("creator")}
                            >
                                <img
                                    className="brand-image"
                                    src={selected === "creator" ? CreatorSelected : Creator}
                                />
                                <label className="brand-text">
                                    Creator
                                    <Checkbox
                                        sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                                        checked={selected === "creator"}
                                        onChange={() => setSelected("creator")}
                                    />
                                </label>
                            </Box>
                        </Box>
                        <Box className="buttons-wrapper">
                            {step > 0 && (
                                <Button
                                    startIcon={<ArrowBack />}
                                    variant="outlined"
                                    onClick={handleBack}
                                    className="back-button"
                                >
                                    Back
                                </Button>
                            )}
                            <Button disabled={selected === ""}  variant="contained" onClick={handleNext} className="next-button">
                                {step < 4 ? "Next" : "Submit"}
                            </Button>
                        </Box>
                    </Box>
                </Slide>
            )}
            {step === 1 && (
                <Slide direction="left" in={step === 1} mountOnEnter unmountOnExit>
                    <Box className="step-wrapper">
                        <BrandFormStepHeader
                            title="Introduce Your Brand"
                            description="Let's start with your brand's basic details"
                        />
                        <Box className="brand-details-form">
                            <CustomTextField
                                name="companyName"
                                label="Brand Name"
                                value={brandInfo.companyName}
                                onChange={handleInputChange}
                            />
                            <CustomTextField
                                name="website"
                                label="Website"
                                value={brandInfo.website}
                                onChange={handleInputChange}
                            />
                        </Box>
                        <Box className="buttons-wrapper">
                            {step > 0 && (
                                <Button
                                    startIcon={<ArrowBack />}
                                    variant="outlined"
                                    onClick={handleBack}
                                    className="back-button"
                                >
                                    Back
                                </Button>
                            )}
                            <Button onClick={handleNext}  variant="contained" className="next-button">
                                {step < 4 ? "Next" : "Submit"}
                            </Button>
                        </Box>
                    </Box>
                </Slide>
            )}
            {step === 2 && (
                <Slide direction="left" in={step === 2} mountOnEnter unmountOnExit>
                    <Box className="step-wrapper">
                        <BrandFormStepHeader
                            title="We'd Love to Contact You!"
                            description="We use your contact details for updates and to tailor suggestions according to your industry."
                        />
                        <Box className="brand-details-form">
                            <CustomTextField
                                name="contactPerson"
                                label="Contact Person"
                                value={brandInfo.contactPerson}
                                onChange={handleInputChange}
                            />
                            <CustomTextField
                                name="contactEmail"
                                label="Contact Email"
                                value={brandInfo.contactEmail}
                                onChange={handleInputChange}
                            />
                            <CustomTextField
                                name="contactPhoneNumber"
                                label="Contact Phone Number"
                                value={brandInfo.contactPhoneNumber}
                                onChange={handleInputChange}
                            />
                            <CustomTextField
                                name="companyAddress"
                                label="Company Address"
                                value={brandInfo.companyAddress}
                                onChange={handleInputChange}
                            />
                            <CustomTextField
                                name="industryCategory"
                                label="Industry"
                                value={brandInfo.industryCategory}
                                onChange={handleInputChange}
                            />
                        </Box>
                        <Box className="buttons-wrapper">
                            {step > 0 && (
                                <Button
                                    startIcon={<ArrowBack />}
                                    variant="outlined"
                                    onClick={handleBack}
                                    className="back-button"
                                >
                                    Back
                                </Button>
                            )}
                            <Button onClick={handleNext} variant="contained" className="next-button">
                                {step < 4 ? "Next" : "Submit"}
                            </Button>
                        </Box>
                    </Box>
                </Slide>
            )}
            {step === 3 && (
                <Slide direction="left" in={step === 3} mountOnEnter unmountOnExit>
                    <Box className="step-wrapper">
                        <BrandFormStepHeader
                            title="Tell us more about your brand!"
                            description="Share a brief description of your brand. This will help creators understand your brand better."
                        />
                        <Box className="brand-details-form">
                            <CustomTextField
                                name="brandBio"
                                label="Brand Bio"
                                multiline
                                sx={{width: '80%'}}
                                rows={6}
                                value={brandInfo.brandBio}
                                onChange={handleInputChange}
                            />
                        </Box>
                        <Box className="buttons-wrapper">
                            {step > 0 && (
                                <Button
                                    startIcon={<ArrowBack />}
                                    variant="outlined"
                                    onClick={handleBack}
                                    className="back-button"
                                >
                                    Back
                                </Button>
                            )}
                            <Button onClick={handleNext} variant="contained" className="next-button">
                                {step < 4 ? "Next" : "Submit"}
                            </Button>
                        </Box>
                    </Box>
                </Slide>
            )}
            {step === 4 && (
                <Slide direction="left" in={step === 4} mountOnEnter unmountOnExit>
                    <Box className="step-wrapper">
                        <BrandFormStepHeader
                            title="Share your social presence!"
                            description="Provide us with your social media handles and your logo. This will enhance your profile visibility."
                        />
                        <Box className="brand-details-form">
                            <CustomTextField
                                name="socialMediaHandles"
                                label="Social Media Handles"
                                value={brandInfo.socialMediaHandles}
                                onChange={handleInputChange}
                            />
                            {/* This should ideally be a file upload, but for simplicity it's a text input */}
                            <CustomTextField
                                name="logo"
                                label="Logo URL"
                                value={brandInfo.logo}
                                onChange={handleInputChange}
                            />
                        </Box>
                        <Box className="buttons-wrapper">
                            {step > 0 && (
                                <Button
                                    startIcon={<ArrowBack />}
                                    variant="outlined"
                                    onClick={handleBack}
                                    className="back-button"
                                >
                                    Back
                                </Button>
                            )}
                            <Button onClick={handleNext} variant="contained" className="next-button">
                                {step < 4 ? "Next" : "Submit"}
                            </Button>
                        </Box>{" "}
                    </Box>
                </Slide>
            )}
            <CustomDialog openModal={showCreationModal} onClose={() => setShowCreationModal(false)}>
                <Box className="modal-profile-container">
                    <Box sx={{position: 'relative'}}>
                        <img src={CraftingProfile} className="modal-profile-img" />
                        <LinearProgress sx={{
                            position: 'absolute',
                            width: '100%',
                            bottom: '25px'
                        }} progress={100}
                                        variant={showConfetti ? "determinate" : "indeterminate"}/>
                    </Box>
                    <Box className="modal-campaign-title">
                        {
                            error ? <Box sx={{color: 'red'}}>
                                Oops! Something went wrong
                            </Box> : (
                                showConfetti ? "Your profile is ready!" : "We are crafting your profile!"
                            )
                        }
                    </Box>
                    <Box className="modal-campaign-description">
                        We fetch and analyse all data related to your youtube channel to create
                        the most informative profile for you. This may take a few minutes.
                    </Box>
                    <Button className="animated-button" variant="contained" onClick={(e)=> (handleSubmit(e))}>
                        Done!
                    </Button>
                </Box>
            </CustomDialog>
        </Box>
    );
};

export default Register;
