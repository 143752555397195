import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {useContext, useState} from "react";
import { useNavigate } from 'react-router-dom';
import {UserContext} from "../../context/UserContext";
import UsersIcon from '@heroicons/react/24/outline/UserCircleIcon';
import LogoutIcon from '@heroicons/react/24/outline/ArrowLeftOnRectangleIcon';
import {SvgIcon} from "@mui/material";

const settings = [{label: 'Profile', icon: <UsersIcon />}, { label: 'Logout', icon: <LogoutIcon />}];

function ResponsiveAppBar({handleDrawerOpen, isOpened}) {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const { user, handleLogout} = useContext(UserContext);

    const navigate = useNavigate();

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutFromTopBar = () => {
        handleLogout()
        handleCloseUserMenu();
    };

    const handleNavigation = (page) => {
        switch (page) {
            case 'Feed':
                navigate('/feed');
                break;
            case 'Dashboard':
                navigate('/dashboard');
                break;
            case 'Analytics':
                navigate('/analytics');
                break;
            case 'Blog':
                navigate('/blog');
                break;
            case 'Profile':
                user.type  === "creator" ? navigate('/creator-profile'): navigate('/brand-profile')
                break;
            case 'Collaborations':
                navigate('/collaborations');
                break;
            case 'Account':
                navigate('/account');
                break;
            case 'Settings':
                navigate('/settings');
                break;
            case 'Logout':
                handleLogoutFromTopBar();
                break;
            default:
                break;
        }
    };
    return (
            <Container maxWidth="100%">
                <Toolbar  disableGutters sx={{maxWidth:"100%", justifyContent: 'space-between'}}>
                    <Box display="flex" alignItems="center">
                    <IconButton
                        color="#000"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(isOpened && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="" src={user?.type === "creator" ? user?.channelDetails?.logo : user?.logo} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={()=>
                                {
                                    handleCloseUserMenu()
                                    handleNavigation(setting.label)
                                }}>
                                    <SvgIcon sx={{marginRight: '4px'}} fontSize="small">
                                        {setting.icon}
                                    </SvgIcon>
                                    <Typography textAlign="center">{setting.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
    );
}
export default ResponsiveAppBar;