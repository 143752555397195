import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Slide, FormHelperText, DialogContentText, Alert, Box
} from '@mui/material';
import "./styles.css"
import {TransitionProps} from "@mui/material/transitions";
import axios from "axios";
import {UserContext} from "../../context/UserContext";
import {useApiClient} from "../../hooks/useApiClient";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TagSponsorshipModal({ open, onClose, videoId, refresh, sponsorshipDetails}) {
    const [brandId, setBrandId] = useState(null);
    const [campaignId, setCampaignId] = useState(null);
    const [proposalId, setProposalId] = useState(null);
    const [adType, setAdType] = useState(null);
    const [payPerViewRate, setPayPerViewRate] = useState(null);
    const { user } = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState(null);
    const apiClient = useApiClient();
    const handleClose = () => {
        onClose();
    };

    const isEmpty = !brandId || !campaignId || !proposalId || !adType;
    const handleSave = async () => {
        // Here make your POST request to '/tag-sponsorship' with the videoId, creatorId and brandId as data
        // After successful POST request, close the modal
        // You might also want to add error handling here
        try {
            const res = await apiClient.post(`/wire-sponsorship`,{
                video_id: videoId,
                brand_id: brandId,
                creator_id: user._id,
                campaign_id: campaignId,
                proposal_id: proposalId,
                adType: adType});

            refresh()
        } catch (e) {
            console.log(e)
            if (e.response) {
                setErrorMessage(e.response.data.error);
            } else {
                setErrorMessage("An error occurred while creating the sponsorship.");
            }
        }
        onClose();
    };

    return (
        <>
            {errorMessage && (
                <Alert className="alert-error" severity="error" onClose={() => setErrorMessage(null)}>
                    {errorMessage}
                </Alert>
            )}
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            TransitionComponent={Transition}
            keepMounted>
            <Box className="dialog-container">
            <DialogTitle>Start Monitoring</DialogTitle>
            <DialogContentText className="dialog-description">
                Choose a brand and the
            </DialogContentText>
            <DialogContent className="dialog-content">
                <FormControl className="brand-selector">
                    <InputLabel id="brand-select-label">Brand</InputLabel>
                    <Select
                        labelId="brand-select-label"
                        id="brand-select"
                        value={brandId}
                        label="Brand"
                        onChange={(event) => setBrandId(event.target.value)}
                    >
                        {
                            sponsorshipDetails?.brands.map(brand => (<MenuItem value={brand.id}>{brand.name}</MenuItem>))
                        }
                    </Select>
                    <FormHelperText>Select a brand to associate with this video.</FormHelperText>
                </FormControl>
                <FormControl className="brand-selector">
                    <InputLabel id="campaign-select-label">Campaign</InputLabel>
                    <Select
                        labelId="campaign-select-label"
                        id="campaign-select"
                        value={campaignId}
                        label="Campaign"
                        onChange={(event) => setCampaignId(event.target.value)}
                    >
                        {
                            sponsorshipDetails?.campaigns.map(campaign => (<MenuItem value={campaign.id}>{campaign.title}</MenuItem>))
                        }
                    </Select>
                </FormControl>
                {/*now select proposal*/}
                <FormControl className="brand-selector" >
                    <InputLabel id="proposal-select-label">Proposal</InputLabel>
                    <Select
                        labelId="proposal-select-label"
                        id="proposal-select"
                        value={proposalId}
                        label="Proposal"
                        onChange={(event) => setProposalId(event.target.value)}
                    >
                        {
                            sponsorshipDetails?.proposals.map(proposal => (<MenuItem value={proposal._id}>{proposal._id}</MenuItem>))
                        }
                    </Select>
                </FormControl>
                <FormControl className="brand-selector" >
                    <InputLabel id="adType-select-label">Ad Type</InputLabel>
                    <Select
                        labelId="adType-select-label"
                        id="adType-select"
                        value={adType}
                        label="adType"
                        onChange={(event) => setAdType(event.target.value)}
                    >
                        {
                            sponsorshipDetails?.adTypes.map(ad => (<MenuItem value={ad}>{ad}</MenuItem>))
                        }
                    </Select>
                </FormControl>
                {/* You can add more TextField components for other fields as needed */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button disabled={isEmpty} onClick={handleSave}>Save</Button>
            </DialogActions>
            </Box>
        </Dialog>
            </>
    );
}

export default TagSponsorshipModal;
