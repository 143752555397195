import React from "react";
import {Box} from "@mui/material";
import Chat from "../../components/Chat";
import "./styles.css";

const Garage = () => {

    return (
       <Box className="garage-page">
               <Chat />
       </Box>
    )
}
export default Garage;