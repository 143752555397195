import {
    Box,
    Checkbox,
    Card,
    CardContent,
    Typography,
    CardActionArea,
    FormControl,
    FormLabel,
    RadioGroup, Radio, FormControlLabel, Input, Fade, Slide
} from '@mui/material';
import './styles.css'
import ValidationBehaviorView from "../DatePicker";
import NumericCurrencyInput from "../NumericCurrencyInput";
import {useEffect, useState} from "react";

function CheckCircle() {
    return (
        <div className="circle-container">
            <svg className="checkmark" viewBox="0 0 52 52">
                <circle className="circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="check" d="M14 27l7 7 16-16" fill="none"/>
            </svg>
        </div>
    );
}


const ProposalSelectCard = ({ title, description, image,imageSelected, selected, onChange, duration=false }) => {

    const [feeType, setFeeType] = useState('fixed');
    const [feeAmount, setFeeAmount] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        }, [feeAmount]);
    const handleUpdate = (updates = {}) => {
        const updatedData = {
            title,
            feeType,
            feeAmount,
            date: selectedDate,
            ...updates
        };
        onChange(updatedData);
    };

    return (
        <Box
            variant="outlined"
            sx={{ border: '1px solid', borderColor: selected ? '#4CAF50' : 'blue' , boxShadow: selected ? '0 0 5px rgb(76 175 80 / 48%)' : 'none', paddingBottom: '32px'}}
        >
            <Box disableRipple>
                <Box display="flex">
                    <Box flex={3}>
                        <CardContent>
                            <Box display="flex" alignItems="center" >
                                <Typography variant="h6">{title}</Typography>
                                {
                                    duration && <Box className="duration-amount">
                                        {`(${duration} seconds)`}
                                    </Box>
                                }
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                                {description}
                            </Typography>
                        </CardContent>
                    </Box>
                    <Box flex={1} display="flex" justifyContent="center" alignItems="center">
                        {
                            selected ? <CheckCircle /> :
                                <img src={ image} alt={title} style={{ maxWidth: '100%' }} />
                        }
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0px 32px'}}>
                    <FormControl className="fee-form-control">
                        <FormLabel>Choose Fee type</FormLabel>
                        <RadioGroup
                            className="fee-radio-group"
                            row value={feeType}
                            onChange={(e) => {
                                const newFeeType = e.target.value;
                                setFeeType(newFeeType);
                                handleUpdate({ feeType: newFeeType });
                            }}
                        >
                            <FormControlLabel className="fee-label" value="fixed" control={<Radio disableRipple />} label="Fixed" />
                            <FormControlLabel disabled={true}  className="fee-label" value="cpm" control={<Radio disableRipple />} label="CPM" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl className="fee-form-control">
                        <FormLabel>Fee Amount</FormLabel>
                        <NumericCurrencyInput
                            placeholder="$"
                            handleChange={(e) => {
                                const newValue = e.target.value;
                                setFeeAmount(newValue);
                                handleUpdate({ feeAmount: newValue });
                            }}
                        />
                    </FormControl>

                </Box>
                <Slide direction="up" unmountOnExit in={feeAmount !== 0 && feeAmount !== ''}><Box sx={{marginTop: 1, marginLeft: 4, marginRight: 4}}>
                    <FormLabel className="publish-date-label">Choose Publishing Date</FormLabel>
                    <ValidationBehaviorView
                        fixed={false}
                        onChange={(date) => {
                            setSelectedDate(date);
                            handleUpdate({ date });
                        }}
                        value={selectedDate}
                    />
                </Box></Slide>

            </Box>
        </Box>
    );
};

export default ProposalSelectCard;
