// Create a new component, let's call it CustomTextField

import { TextField } from "@mui/material";

const CustomTextField = ({name, label, value, onChange, required = false, ...rest}) => {
  const handleChange = (e) => {
    onChange(e);
  }

  return (
    <TextField
      sx={{width: '100%'}}
      required={required}
      name={name}
      label={label}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default CustomTextField;
