import React, {useState} from "react";
import {Avatar, Box, FormControl, InputLabel, MenuItem, Select, SvgIcon} from "@mui/material";
import './styles.css'
import CalendarICon from './../../assets/calendar-icon.svg'
import CampaignBudget from './../../assets/campaign-budget.svg'
import {ReactComponent as BudgetIcon} from "../../assets/icon_money_bag.svg";
import CalendarIcon from "../../assets/icon_calendar_date.svg"
import ReactECharts from 'echarts-for-react';
import Button from "../Button";
import {Pause, PlayArrow} from "@mui/icons-material";
import Collapse from '@mui/material/Collapse';
import ProposalDetails from "../ProposalCard";
import {useApiClient} from "../../hooks/useApiClient";

const getOption = (count, title, color, titleSize=12) => {
    return {
        title: {
            text: title,
            left: 'center',
            padding: -1,
            textStyle: {
                fontSize: titleSize
            },
        },
        series: [
            {
                color:color,
                name: 'Proposals',
                type: 'pie',
                radius: ['40%', '55%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                data: [
                    { value: count, name: `${count}` },

                ]
            },
        ]
    };
}

const CampaignCard = ({campaign, refresh}) => {

    const creationDate = new Date(campaign?.creation_timestamp);
    const duration = campaign?.details?.campaignDuration;
    const durationDays = parseInt(duration.split(' ')[0]);
    const [isContentVisible, setContentVisibility] = useState(false);
    const apiClient = useApiClient();

    const [filterStatus, setFilterStatus] = useState("All");

    const handleFilterChange = (event) => {
        setFilterStatus(event.target.value);
    };

    let filteredProposals = campaign?.proposals || [];
    if (filterStatus !== "All") {
        filteredProposals = filteredProposals.filter(proposal => proposal.status === filterStatus);
    }


    const totalProposals = campaign?.proposals?.length || 0;
    const pendingProposals = campaign?.proposals?.filter(p => p.status === "pending").length || 0;
    const inProgressProposals = campaign?.proposals?.filter(p => p.status === "in_progress").length || 0;
    const completedProposals = campaign?.proposals?.filter(p => p.status === "completed").length || 0;

    const handlePieClick = () => {
        setContentVisibility(prevState => !prevState);
    }

    const handlePauseCampaign = async () => {

            try {
                const res = await apiClient.put(`/campaign/${campaign?._id}`, {
                    campaign_status: campaign.campaign_status === "paused" ? "active" : "paused"
                });
                refresh();
            } catch (e) {

            } finally {

        }
    }

    // Calculate expiry date
    const expiryDate = new Date(creationDate);

    expiryDate.setDate(expiryDate.getDate() + durationDays);

    // Get current date in UTC
    const currentDate = new Date();

    // Calculate remaining days
    const timeDifference = expiryDate - currentDate;
    let remaining = Math.ceil(timeDifference / (1000 * 3600 * 24));
    remaining = remaining <= 0 ? "Expired" : `${remaining} Days `
    const isPaused = campaign.campaign_status === 'paused'

    return (<Box className="campaign-card">
        <Box className="campaign-content">
            <Box className="campaign-left-container">
                <Box className="title-section">
                    <Box className="campaign-title">
                        <div className={remaining === "Expired" ? "inactive-campaign" : (isPaused ? "paused-campaign" :"active-campaign")} />
                        {campaign?.details?.title}
                    </Box>
                    <Box className="campaign-days-left">

                        {remaining === "Expired" ? <Box display="flex" flexDirection="row">
                            CAMPAIGN: <Box className="campaign-days-left-value">{remaining}</Box>
                        </Box> : <Box display="flex" flexDirection="row">
                            CAMPAIGN EXPIRES: <Box className="campaign-days-left-value">{remaining}</Box>
                        </Box>}
                    </Box>
                    <Box className="campaign-right-container">
                        <div className="icon-wrapper">
                            <SvgIcon className="feed-icon" component={BudgetIcon} inheritViewBox/>
                            <div className="icon-text" variant="caption">
                                <div className="icon-text-title">Budget</div>
                                <div className="icon-text-value">{campaign?.details?.budget}</div>
                            </div>
                        </div>
                        <div className="icon-wrapper">
                            <img className="feed-icon" src={CalendarIcon}/>
                            <div className="icon-text" variant="caption">
                                <div className="icon-text-title">Duration</div>
                                <div className="icon-text-value">{campaign?.details?.campaignDuration}</div>
                            </div>
                        </div>
                    </Box>
                    <Box className="campaign-buttons-wrapper">
                        <Button sx={{padding:'0px 30px !important'}} onClick={handlePauseCampaign} variant="outlined"
                                startIcon={isPaused ? <PlayArrow /> : <Pause />}>
                            {isPaused ? "Resume" : "Pause"}
                        </Button>
                        <Button sx={{padding:'0px 30px !important'}}>
                            Edit
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box className="campaign-body-bottom">
                <ReactECharts
                    option={getOption(totalProposals, "Total Proposals", "#5470C6", 15)} style={{ height: '120px', width: '120px' }} />

                <Box className="proposals-wrapper">
                <ReactECharts option={getOption(pendingProposals, "Pending", "#1e88e5")} style={{ height: '85px', width: '85px' }} />
                <ReactECharts option={getOption(inProgressProposals, "In Progress", "#FFC107")} style={{ height: '85px', width: '85px' }} />
                <ReactECharts option={getOption(completedProposals, "Completed", "#4caf50")} style={{ height: '85px', width: '85px' }} />
                </Box>
                <Button onClick={handlePieClick}
                        sx={{borderRadius: 0}}
                        variant="outlined"
                        className="view-prop-button">
                    View Proposals
                </Button>
            </Box>
        </Box>
        <Collapse sx={{ mt: 3, width: '100%' }} in={isContentVisible}>
            <Box sx={{ padding: '16px',
                marginBottom: 1,
                border: '1px solid #e8e8e8e8' , justifyContent: 'space-between'}} display="flex" alignItems="center">
                <Box display="flex" alignItems="center" gap={3}>
                    <Box>
                        Filter by Proposal Status:
                    </Box>
                    <FormControl sx={{width: '165px'}}>
                        <Select
                            sx={{borderRadius: 0}}
                            labelId="filter-label"
                            value={filterStatus}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value={"All"}>All</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"in_progress"}>In Progress</MenuItem>
                            <MenuItem value={"completed"}>Completed</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    {filteredProposals.length} Proposals
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4}}>
                {filteredProposals.map(proposal => (
                    <Box width="100%" key={proposal._id}>
                        <ProposalDetails proposal={proposal} />
                    </Box>
                ))}
            </Box>
        </Collapse>
    </Box>)
}
export default CampaignCard;