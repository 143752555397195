import React, {useContext} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import CreatorProfile from './pages/CreatorProfile';
import BrandProfile from './pages/BrandProfile';
import PartnershipOpportunities from './pages/PartnershipOpportunities';
import PartnershipDetails from './pages/PartnershipDetails';
import CollaborationTracking from './pages/CollaborationTracking';
import Settings from './pages/Settings';
import HelpSupport from './pages/HelpSupport';
import Dashboard from './pages/Dashboard/creator';
import PrivateRoute from './components/PrivateRoutes';
import Feed from "./pages/Feed";
import Analytics from "./pages/Analytics";
import Register from "./pages/Register";
import WithUserType from "./pages/Dashboard/withUserType";
import Campaigns from "./pages/Campaigns";
import CampaignCreator from "./pages/Campaigns/CampaignCreator";
import Details from "./pages/Campaigns/CampaignCreator/Details";
import AdSet from "./pages/Campaigns/CampaignCreator/AdSet";
import Preview from "./pages/Campaigns/CampaignCreator/Preview";
import {CampaignProvider} from "./context/CampaignContext";
import Sponsorshipwizard from "./pages/Sponsorshipwizard";
import ProposalAdset from "./pages/Sponsorshipwizard/ProposalAdset";
import ProposalDetails from "./pages/Sponsorshipwizard/ProposalDetails";
import Hires from "./pages/Hires";
import FundHires from "./pages/FundHires";
import Garage from "./pages/Garage";
import Sponsorships from "./pages/Sponsorships";

const AppRoutes = () => {

    return (
        <Router>
            <Routes>
                <Route path="/register" element={<PrivateRoute><Register /></PrivateRoute>} />
                    <Route path="/" element={<Home />} />
                    <Route path="/creator-profile" element={<PrivateRoute><CreatorProfile /></PrivateRoute>} />
                    <Route path="/brand-profile" element={<PrivateRoute><BrandProfile /></PrivateRoute>} />
                    <Route path="/dashboard" element={<PrivateRoute><WithUserType /></PrivateRoute>} />
                    <Route path="/campaigns" element={<PrivateRoute><Campaigns /></PrivateRoute>} />
                    <Route path="/campaign/create" element={<PrivateRoute>
                        <CampaignCreator /></PrivateRoute>}>
                        <Route path="details" element={<Details />} />
                        <Route path="adset" element={<AdSet />} />
                        <Route path="preview" element={<Preview />} />
                    </Route>
                <Route path="/campaign/:campaignId/proposal/create" element={<PrivateRoute>
                    <Sponsorshipwizard />
                </PrivateRoute>}>
                    <Route path="adset" element={<ProposalAdset />} />
                    <Route path="details" element={<ProposalDetails />} />
                </Route>
                    <Route path="/feed" element={<PrivateRoute><Feed /></PrivateRoute>} />
                    <Route path="/sponsorships" element={<PrivateRoute><Sponsorships /></PrivateRoute>} />
                    <Route path="/analytics" element={<PrivateRoute><Analytics /></PrivateRoute>} />
                    <Route path="/garage" element={<PrivateRoute><Garage /></PrivateRoute>} />
                    <Route path="/partnership-opportunities" element={<PrivateRoute><PartnershipOpportunities /></PrivateRoute>} />
                    <Route path="/partnership-details" element={<PrivateRoute><PartnershipDetails /></PrivateRoute>} />
                    <Route path="/hires" element={<PrivateRoute><Hires /></PrivateRoute>} />
                    <Route path="/hires/fund" element={<PrivateRoute><FundHires /></PrivateRoute>} />
                    <Route path="/collaboration-tracking" element={<PrivateRoute><CollaborationTracking /></PrivateRoute>} />
                    <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                    <Route path="/register" element={<PrivateRoute><Settings /></PrivateRoute>} />
                    <Route path="/help-support" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
                </Routes>
        </Router>
    );
};

export default AppRoutes;
