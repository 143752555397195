import * as React from 'react';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./styles.css"
import {Box, TablePagination, Toolbar} from "@mui/material";
import {useMemo, useState} from "react";

const CustomTableCell = styled(TableCell)`
  background-color: #fdfdfd;
  padding: 24px;
  max-width: 280px;
`;


export default function SponsorshipsTable({rows, columns}) {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const visibleRows = useMemo(
        () =>
            rows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [page, rowsPerPage],
    );


    return (
        <div className="table-wrapper">
            <Toolbar className="table-toolbar">
                <Box className="section-title">Sponsorships</Box>
                <div className="live-wrapper">
                    <div className="live-status" />Live
                </div>
            </Toolbar>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <CustomTableCell key={column.field} align={column.align}>{column.headerName}</CustomTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {visibleRows.map((row) => (
                        <TableRow key={row.current_views_count} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {columns.map((column) => (
                                <CustomTableCell key={column.field} align={column.align} component={column.component} scope={column.scope}>
                                    {column.renderCell ? column.renderCell(row) : row[column.field]}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}