import React from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button} from "@mui/material";
import CampaignField from "../components/CampaignField";
import {useCampaign} from "../../../context/CampaignContext";
import {INTEGRATION_TYPES, SEGMENTS} from "../../Campaigns";
import SelectCard from "../../../components/SelectCard";
import AdvancedCardSelect from "../../../components/AdvancedSelectCard";
import ValidationBehaviorView from "../../../components/DatePicker";
import Audience from "../../../components/Audience";
import ProposalSelectCard from "../../../components/ProposalSelectCard";
import {useProposal} from "../../../context/ProposalContext";
import proposalDetails from "../ProposalDetails";

const isCardReady = (cardData) => {
    return cardData?.feeAmount && cardData?.date;
};

const IntegrationTypesList = () => {
    const {proposalData,setProposalData} = useProposal();
    const { campaignData, setCampaignData } = useCampaign();

    const handleCardSelect = (title) => (data) => {

        setProposalData(prevData => ({
            ...prevData,
                integrationTypes: {
                    ...prevData.integrationTypes,
                    [title]: data
                }
        }));
    };

    return (
        <Box>
            <Box>
                <Box className="integration-title">
                    Integration Types the Brand Prefers:
                </Box>
                <Box className="integration-description">
                    In-content integrations effortlessly blend with your video's theme, providing an authentic touch for brands and creating a captivating experience for your viewers.
                </Box>
            </Box>
            {INTEGRATION_TYPES.filter(option => campaignData.adSet.integrationTypes[option.title]).map((option, index) => (
                <Box className="campaign-creator-card-select" key={index}>
                    <ProposalSelectCard
                        selected={isCardReady(proposalData.integrationTypes[option.title])}
                        onChange={handleCardSelect(option.title)}
                        {...option}
                    />
                </Box>
            ))}

        </Box>

    );
}


const PromoSegmentsList = () => {
    const {proposalData,setProposalData} = useProposal();
    const { campaignData, setCampaignData } = useCampaign();

    const handleCardSelect = (title) => (data) => {

        setProposalData(prevData => ({
            ...prevData,
            promoSegments: {
                ...prevData.promoSegments,
                [title]: data
            }
        }));
    };

    return (
        <Box>
            <Box>
                <Box className="integration-title">
                    Precise Brand Insertion Points You Can Offer:
                </Box>
                <Box className="integration-description">
                    Choose how you'd like to showcase the brand in your videos. Opt for an opening feature, engagement slot, closing note, or a visual highlight.
                </Box>
            </Box>
            {SEGMENTS.filter(option => campaignData.adSet.promoSegments[option.title]?.selected).map((option, index) => {
                return(
                <Box className="campaign-creator-card-select" key={index}>
                    <ProposalSelectCard
                        selected={isCardReady(proposalData.promoSegments[option.title])}
                        duration={campaignData.adSet.promoSegments[option.title].duration || "15"}
                        onChange={handleCardSelect(option.title)}
                        {...option}
                    />
                </Box>
            )})}

        </Box>

    );
}


const ProposalAdset = () => {
    const { campaignData, setCampaignData } = useCampaign();
    const {proposalData,setProposalData} = useProposal();

    const hasValidTypes = (typesObject) => {
        return typesObject ?
            Object.values(typesObject).some(
                type => type.feeAmount && type.date
            ) : false;
    };
    const hasValidIntegrationTypes = hasValidTypes(proposalData.integrationTypes);
    const hasValidPromoSegments = hasValidTypes(proposalData.promoSegments);

    const isEmpty =
        (!hasValidIntegrationTypes || proposalData.integrationTypes.length === 0) &&
        (!hasValidPromoSegments || proposalData.promoSegments.length === 0);
    const navigate = useNavigate();

    const handleNext = () => {
        window.scrollTo(0, 0);
        navigate(`/campaign/${campaignData._id}/proposal/create/details`);
    }

    return (
        <Box className="campaign-creator-adset">
            <IntegrationTypesList />
            <PromoSegmentsList />
            <Button
                className="next-button-campaign"
                variant="contained"
                color="primary" onClick={handleNext}
                disabled={isEmpty}>
                Next
            </Button>
        </Box>
    );
}
export default ProposalAdset;