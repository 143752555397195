import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import { useRef, useState } from "react";

export const BrandProfileMeta = ({ details }) => {
    const [logo, setLogo] = useState(details?.logo || '');
    const fileInputRef = useRef(null);

    const handleLogoChange = (event) => {
        const uploadedLogo = URL.createObjectURL(event.target.files[0]);
        setLogo(uploadedLogo);
        // You may also need to save the logo to your backend or update the 'details' object with the new logo URL
        // Example: saveLogoToBackend(uploadedLogo);
    };

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <Card>
            <CardContent>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Avatar
                        src={logo} // Update 'src' prop here
                        sx={{
                            height: 80,
                            mb: 2,
                            width: 80
                        }}
                    />
                    <Typography gutterBottom variant="h5">
                        {details?.companyName}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                        {details?.industryCategory}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                        {details?.website}
                    </Typography>
                </Box>
            </CardContent>
            <Divider />
            <CardActions>
                <input
                    accept="image/*"
                    id="logo-upload"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleLogoChange}
                    ref={fileInputRef}
                />
                <Button
                    fullWidth
                    variant="text"
                    onClick={handleUploadButtonClick}
                >
                    Upload picture
                </Button>
            </CardActions>
        </Card>
    );
};
