import React, { useState } from 'react';
import { Box, Button } from "@mui/material";
import "./../styles.css"
import CampaignField from "../components/CampaignField";
import MultipleSelectChip from "../../../../components/MultiSelect";
import CustomTextField from "../../../../components/CustomTextField";
import CustomSelect from "../../../../components/Select";
import {useCampaign} from "../../../../context/CampaignContext";
import {useNavigate} from "react-router-dom";
import Campaign1 from "./../../../../assets/campaign1.svg"
import Campaign2 from "./../../../../assets/campaign2.svg"

const DURATION_LIST = [
   "7 days",
   "14 days",
   "30 days",
   "45 days",
   "60 days",
   "90 days",
];

const BUDGET_LIST = [
    "Under $1,000",
    "Up to $5,000",
    "Up to $25,000",
    "Up to $50,000",
    "Up to $100,000",
    "Over $100,000",
];

const youtubeCategories = [
    "Film & Animation",
    "Autos & Vehicles",
    "Music",
    "Pets & Animals",
    "Sports",
    "Short Movies",
    "Travel & Events",
    "Gaming",
    "Videoblogging",
    "People & Blogs",
    "Comedy",
    "Entertainment",
    "News & Politics",
    "Howto & Style",
    "Education",
    "Science & Technology",
    "Nonprofits & Activism",
    "Movies",
    "Anime/Animation",
    "Action/Adventure",
    "Classics",
    "Documentary",
    "Drama",
    "Family",
    "Foreign",
    "Horror",
    "Sci-Fi/Fantasy",
    "Thriller",
    "Shorts",
    "Shows",
    "Trailers"
];


const Details = ({sync}) => {

    const { campaignData, setCampaignData } = useCampaign();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCampaignData(prevData => ({
            ...prevData,
            details: {
                ...prevData.details,
                [name]: value
            }
        }));
    }

    const handleNext = () => {
        window.scrollTo(0, 0);
        navigate('/campaign/create/adset');
    }

    return (
        <Box display="flex" flexDirection="row">
            <img src={Campaign1} className="campaign1-img"/>
            <Box  className="campaign-creator-details">
                <CampaignField
                    title="Campaign Title"
                    description="Catch the attention of YouTube creators with a title that reflects your brand's uniqueness."
                >
                    <CustomTextField
                        name="title"
                        label="Enter Campaign Name"
                        value={campaignData.details.title}
                        onChange={handleChange}
                    />
                </CampaignField>
                <CampaignField
                    title="Brand Web Link"
                    description="Share a link to your website or product page to help creators get familiar with your brand."
                >
                    <CustomTextField
                        name="brandURL"
                        value={campaignData.brandURL}
                        onChange={handleChange}
                        placeholder="https://brand-url.com"
                    />
                </CampaignField>
                <CampaignField
                    title="Performance Tracker"
                    description="Evaluate your ad's efficacy by incorporating our attribution tracker into your campaign."
                >
                    <CustomTextField
                        name="attributionSetup"
                        value={campaignData.details.attributionSetup}
                        onChange={handleChange}
                    />
                </CampaignField>
                <CampaignField
                    title="Campaign Overview"
                    description="Offer details about your campaign to YouTube creators."
                >
                    <CustomTextField
                        name="description"
                        value={campaignData.details.description}
                        onChange={handleChange}
                        multiline
                        rows={4}
                    />
                </CampaignField>
                <CampaignField
                    title="Budget"
                    description="Indicate your total budget for this campaign. More competitive budgets can attract a broader range of creators."
                >
                    <CustomSelect
                        name="budget"
                        handleChange={handleChange}
                        value={campaignData.details.budget}
                        list={BUDGET_LIST}/>

                </CampaignField>
                <CampaignField title="YouTube Categories" description="Select the categories that suit your campaign">
                    <MultipleSelectChip
                        categories={youtubeCategories}
                        name="creatorCategories"
                        handleChange={handleChange}
                    />
                </CampaignField>
                <CampaignField
                    title="Campaign Duration"
                    description="Define the length of time your campaign will be open to receive proposals from creators."
                >
                    <CustomSelect
                        name="campaignDuration"
                        handleChange={handleChange}
                        value={campaignData.details.campaignDuration}
                        list={DURATION_LIST}/>

                </CampaignField>

                <Button className="next-button-campaign" variant="contained" color="primary" onClick={handleNext} >
                    Next
                </Button>
            </Box>
            <img src={Campaign2} className="campaign2-img"/>
        </Box>
    );
}

export default Details;